"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINK_CELL_SUCCESS = exports.LINK_CELL_LOADING = exports.LONG_TEXT_CELL_FAILED = exports.LONG_TEXT_CELL_SUCCESS = exports.LONG_TEXT_CELL_LOADING = exports.CLOCK_CELL_FAILED = exports.CLOCK_CELL_SUCCESS = exports.CLOCK_CELL_LOADING = exports.REMOVE_TIME_TRACKING_CELL_FAILED = exports.REMOVE_TIME_TRACKING_CELL_SUCCESS = exports.REMOVE_TIME_TRACKING_CELL_LOADING = exports.EDIT_TIME_TRACKING_CELL_FAILED = exports.EDIT_TIME_TRACKING_CELL_SUCCESS = exports.EDIT_TIME_TRACKING_CELL_LOADING = exports.ADD_TIME_TRACKING_CELL_FAILED = exports.ADD_TIME_TRACKING_CELL_SUCCESS = exports.ADD_TIME_TRACKING_CELL_LOADING = exports.CHECK_BOX_CELL_FAILED = exports.CHECK_BOX_CELL_SUCCESS = exports.CHECK_BOX_CELL_LOADING = exports.DATE_RANGE_CELL_FAILED = exports.DATE_RANGE_CELL_SUCCESS = exports.DATE_RANGE_CELL_LOADING = exports.DATE_CELL_FAILED = exports.DATE_CELL_SUCCESS = exports.DATE_CELL_LOADING = exports.NUMBER_CELL_FAILED = exports.NUMBER_CELL_SUCCESS = exports.NUMBER_CELL_LOADING = exports.TEXT_CELL_FAILED = exports.TEXT_CELL_SUCCESS = exports.TEXT_CELL_LOADING = exports.PERSON_CELL_FAILED = exports.PERSON_CELL_SUCCESS = exports.PERSON_CELL_LOADING = exports.REMOVE_COLUMN_LABEL_FAILED = exports.REMOVE_COLUMN_LABEL_SUCCESS = exports.REMOVE_COLUMN_LABEL_LOADING = exports.UPDATE_COLUMN_LABEL_FAILED = exports.UPDATE_COLUMN_LABEL_SUCCESS = exports.UPDATE_COLUMN_LABEL_LOADING = exports.ADD_COLUMN_LABEL_FAILED = exports.ADD_COLUMN_LABEL_SUCCESS = exports.ADD_COLUMN_LABEL_LOADING = exports.GET_COLUMN_LABEL_FAILED = exports.GET_COLUMN_LABEL_SUCCESS = exports.GET_COLUMN_LABEL_LOADING = exports.STATUS_CELL_FAILED = exports.STATUS_CELL_SUCCESS = exports.STATUS_CELL_LOADING = void 0;
exports.FETCH_LATEST_COMMENT = exports.APPLY_LABEL_CHANGES_TO_CELLS = exports.UPDATE_FILE_PROGRESS_UPLOADS = exports.SET_FILE_UPLOADS = exports.CLEAR_CELL_FAILED = exports.CLEAR_CELL_SUCCESS = exports.CLEAR_CELL_LOADING = exports.PRIORITY_CELL_FAILED = exports.PRIORITY_CELL_SUCCESS = exports.PRIORITY_CELL_LOADING = exports.COUNTRY_CELL_FAILED = exports.COUNTRY_CELL_SUCCESS = exports.COUNTRY_CELL_LOADING = exports.LABEL_CELL_FAILED = exports.LABEL_CELL_SUCCESS = exports.LABEL_CELL_LOADING = exports.DROP_DOWN_CELL_FAILED = exports.DROP_DOWN_CELL_SUCCESS = exports.DROP_DOWN_CELL_LOADING = exports.FILE_CELL_FAILED = exports.FILE_CELL_SUCCESS = exports.FILE_CELL_LOADING = exports.LINK_CELL_FAILED = void 0;
exports.STATUS_CELL_LOADING = "STATUS_CELL_LOADING";
exports.STATUS_CELL_SUCCESS = "STATUS_CELL_SUCCESS";
exports.STATUS_CELL_FAILED = "STATUS_CELL_FAILED";
exports.GET_COLUMN_LABEL_LOADING = "GET_COLUMN_LABEL_LOADING";
exports.GET_COLUMN_LABEL_SUCCESS = "GET_COLUMN_LABEL_SUCCESS";
exports.GET_COLUMN_LABEL_FAILED = "GET_COLUMN_LABEL_FAILED";
exports.ADD_COLUMN_LABEL_LOADING = "ADD_COLUMN_LABEL_LOADING";
exports.ADD_COLUMN_LABEL_SUCCESS = "ADD_COLUMN_LABEL_SUCCESS";
exports.ADD_COLUMN_LABEL_FAILED = "ADD_COLUMN_LABEL_FAILED";
exports.UPDATE_COLUMN_LABEL_LOADING = "UPDATE_COLUMN_LABEL_LOADING";
exports.UPDATE_COLUMN_LABEL_SUCCESS = "UPDATE_COLUMN_LABEL_SUCCESS";
exports.UPDATE_COLUMN_LABEL_FAILED = "UPDATE_COLUMN_LABEL_FAILED";
exports.REMOVE_COLUMN_LABEL_LOADING = "REMOVE_COLUMN_LABEL_LOADING";
exports.REMOVE_COLUMN_LABEL_SUCCESS = "REMOVE_COLUMN_LABEL_SUCCESS";
exports.REMOVE_COLUMN_LABEL_FAILED = "REMOVE_COLUMN_LABEL_FAILED";
exports.PERSON_CELL_LOADING = "PERSON_CELL_LOADING";
exports.PERSON_CELL_SUCCESS = "PERSON_CELL_SUCCESS";
exports.PERSON_CELL_FAILED = "PERSON_CELL_FAILED";
exports.TEXT_CELL_LOADING = "TEXT_CELL_LOADING";
exports.TEXT_CELL_SUCCESS = "TEXT_CELL_SUCCESS";
exports.TEXT_CELL_FAILED = "TEXT_CELL_FAILED";
exports.NUMBER_CELL_LOADING = "NUMBER_CELL_LOADING";
exports.NUMBER_CELL_SUCCESS = "NUMBER_CELL_SUCCESS";
exports.NUMBER_CELL_FAILED = "NUMBER_CELL_FAILED";
exports.DATE_CELL_LOADING = "DATE_CELL_LOADING";
exports.DATE_CELL_SUCCESS = "DATE_CELL_SUCCESS";
exports.DATE_CELL_FAILED = "DATE_CELL_FAILED";
exports.DATE_RANGE_CELL_LOADING = "DATE_RANGE_CELL_LOADING";
exports.DATE_RANGE_CELL_SUCCESS = "DATE_RANGE_CELL_SUCCESS";
exports.DATE_RANGE_CELL_FAILED = "DATE_RANGE_CELL_FAILED";
exports.CHECK_BOX_CELL_LOADING = "CHECK_BOX_CELL_LOADING";
exports.CHECK_BOX_CELL_SUCCESS = "CHECK_BOX_CELL_SUCCESS";
exports.CHECK_BOX_CELL_FAILED = "CHECK_BOX_CELL_FAILED";
exports.ADD_TIME_TRACKING_CELL_LOADING = "ADD_TIME_TRACKING_CELL_LOADING";
exports.ADD_TIME_TRACKING_CELL_SUCCESS = "ADD_TIME_TRACKING_CELL_SUCCESS";
exports.ADD_TIME_TRACKING_CELL_FAILED = "ADD_TIME_TRACKING_CELL_FAILED";
exports.EDIT_TIME_TRACKING_CELL_LOADING = "EDIT_TIME_TRACKING_CELL_LOADING";
exports.EDIT_TIME_TRACKING_CELL_SUCCESS = "EDIT_TIME_TRACKING_CELL_SUCCESS";
exports.EDIT_TIME_TRACKING_CELL_FAILED = "EDIT_TIME_TRACKING_CELL_FAILED";
exports.REMOVE_TIME_TRACKING_CELL_LOADING = "REMOVE_TIME_TRACKING_CELL_LOADING";
exports.REMOVE_TIME_TRACKING_CELL_SUCCESS = "REMOVE_TIME_TRACKING_CELL_SUCCESS";
exports.REMOVE_TIME_TRACKING_CELL_FAILED = "REMOVE_TIME_TRACKING_CELL_FAILED";
exports.CLOCK_CELL_LOADING = "CLOCK_CELL_LOADING";
exports.CLOCK_CELL_SUCCESS = "CLOCK_CELL_SUCCESS";
exports.CLOCK_CELL_FAILED = "CLOCK_CELL_FAILED";
exports.LONG_TEXT_CELL_LOADING = "LONG_TEXT_CELL_LOADING";
exports.LONG_TEXT_CELL_SUCCESS = "LONG_TEXT_CELL_SUCCESS";
exports.LONG_TEXT_CELL_FAILED = "LONG_TEXT_CELL_FAILED";
exports.LINK_CELL_LOADING = "LINK_CELL_LOADING";
exports.LINK_CELL_SUCCESS = "LINK_CELL_SUCCESS";
exports.LINK_CELL_FAILED = "LINK_CELL_FAILED";
exports.FILE_CELL_LOADING = "FILE_CELL_LOADING";
exports.FILE_CELL_SUCCESS = "FILE_CELL_SUCCESS";
exports.FILE_CELL_FAILED = "FILE_CELL_FAILED";
exports.DROP_DOWN_CELL_LOADING = "DROP_DOWN_CELL_LOADING";
exports.DROP_DOWN_CELL_SUCCESS = "DROP_DOWN_CELL_SUCCESS";
exports.DROP_DOWN_CELL_FAILED = "DROP_DOWN_CELL_FAILED";
exports.LABEL_CELL_LOADING = "LABEL_CELL_LOADING";
exports.LABEL_CELL_SUCCESS = "LABEL_CELL_SUCCESS";
exports.LABEL_CELL_FAILED = "LABEL_CELL_FAILED";
exports.COUNTRY_CELL_LOADING = "COUNTRY_CELL_LOADING";
exports.COUNTRY_CELL_SUCCESS = "COUNTRY_CELL_SUCCESS";
exports.COUNTRY_CELL_FAILED = "COUNTRY_CELL_FAILED";
exports.PRIORITY_CELL_LOADING = "PRIORITY_CELL_LOADING";
exports.PRIORITY_CELL_SUCCESS = "PRIORITY_CELL_SUCCESS";
exports.PRIORITY_CELL_FAILED = "PRIORITY_CELL_FAILED";
exports.CLEAR_CELL_LOADING = "CLEAR_CELL_LOADING";
exports.CLEAR_CELL_SUCCESS = "CLEAR_CELL_SUCCESS";
exports.CLEAR_CELL_FAILED = "CLEAR_CELL_FAILED";
exports.SET_FILE_UPLOADS = "SET_FILE_UPLOADS";
exports.UPDATE_FILE_PROGRESS_UPLOADS = "UPDATE_FILE_PROGRESS_UPLOADS";
exports.APPLY_LABEL_CHANGES_TO_CELLS = "APPLY_LABEL_CHANGES_TO_CELLS";
exports.FETCH_LATEST_COMMENT = "FETCH_LATEST_COMMENT";
