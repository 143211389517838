"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.seperateEdges = exports.seperateNodes = void 0;
function seperateNodes(_a) {
    var payloadNodes = _a.payloadNodes, stateNodes = _a.stateNodes;
    var updatedNodes = {};
    var addedNodes = [];
    stateNodes.forEach(function (node) {
        updatedNodes[node.id] = __assign({}, node);
    });
    payloadNodes.forEach(function (node) {
        if (updatedNodes[node.id]) {
            updatedNodes[node.id] = node;
        }
        else {
            addedNodes.push(node);
        }
    });
    return { addedNodes: addedNodes, updatedNodes: Object.values(updatedNodes) };
}
exports.seperateNodes = seperateNodes;
function seperateEdges(_a) {
    var payloadEdges = _a.payloadEdges, stateEdges = _a.stateEdges;
    var added = payloadEdges.filter(function (e) { return !stateEdges.some(function (se) { return se.id === e.id; }); });
    var updated = stateEdges.map(function (e) { return payloadEdges.find(function (pe) { return pe.id === e.id; }) || e; });
    return { addedEdges: added, updatedEdges: updated };
}
exports.seperateEdges = seperateEdges;
function applyUndoRedo(_a) {
    var changes = _a.changes, state = _a.state;
    var elementsCopy = __spreadArray([], state.nodeElements, true);
    var edgesCopy = __spreadArray([], state.edgeElements, true);
    var drilldownNodesCopy = __spreadArray([], state.drilldownNodes, true);
    var addedEdges = changes.addedEdges, addedNodes = changes.addedNodes, removedEdges = changes.removedEdges, removedNodes = changes.removedNodes, updatedEdges = changes.updatedEdges, updatedNodes = changes.updatedNodes, drilldownNodes = changes.drilldownNodes;
    if (removedNodes) {
        removedNodes.forEach(function (removedNode) {
            var index = elementsCopy.findIndex(function (element) { return element.id === removedNode; });
            if (index !== -1)
                elementsCopy.splice(index, 1);
        });
    }
    if (removedEdges) {
        removedEdges.forEach(function (removedEdge) {
            var index = edgesCopy.findIndex(function (edge) { return edge.id === removedEdge; });
            if (index !== -1)
                edgesCopy.splice(index, 1);
        });
    }
    if (updatedNodes) {
        updatedNodes.forEach(function (updatedNode) {
            var index = elementsCopy.findIndex(function (element) { return element.id === updatedNode.id; });
            if (index !== -1)
                elementsCopy[index] = updatedNode;
        });
    }
    if (updatedEdges) {
        updatedEdges.forEach(function (updatedEdge) {
            var index = edgesCopy.findIndex(function (edge) { return edge.id === updatedEdge.id; });
            if (index !== -1)
                edgesCopy[index] = updatedEdge;
        });
    }
    if (addedNodes) {
        elementsCopy.push.apply(elementsCopy, addedNodes);
    }
    if (addedEdges) {
        edgesCopy.push.apply(edgesCopy, addedEdges);
    }
    if (drilldownNodes) {
        drilldownNodes.forEach(function (dn) {
            var node = dn.node, isOpen = dn.isOpen;
            if (isOpen) {
                if (!drilldownNodesCopy.some(function (dnc) { return dnc.id === node.id; })) {
                    drilldownNodesCopy.push(node);
                }
            }
            else {
                drilldownNodesCopy = drilldownNodesCopy.filter(function (dnc) { return dnc.id !== node.id; });
            }
        });
    }
    return {
        nodeElements: elementsCopy,
        edgeElements: edgesCopy,
        drilldownNodes: drilldownNodesCopy,
    };
}
exports.default = applyUndoRedo;
