"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rgbaStringToRgbaObject = exports.rgbaToHex = exports.hexToRgbaString = exports.hexToRgba = exports.getContrastText = void 0;
var material_1 = require("@mui/material");
// Copied from MUI Source code
function getContrastText(background) {
    var contrastText = (0, material_1.getContrastRatio)("rgba(0,0,0,0.87)", background) >= 3
        ? "rgba(0,0,0,0.87)"
        : "#FFFFFF";
    return contrastText;
}
exports.getContrastText = getContrastText;
var hexToRgba = function (hex) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    var a = hex.length > 7 ? parseInt(hex.slice(7, 9), 16) / 255 : 1;
    return { r: r, g: g, b: b, a: a };
};
exports.hexToRgba = hexToRgba;
var hexToRgbaString = function (hex) {
    var rgba = (0, exports.hexToRgba)(hex);
    return "rgba(".concat(rgba.r, ", ").concat(rgba.g, ", ").concat(rgba.b, ", ").concat(rgba.a, ")");
};
exports.hexToRgbaString = hexToRgbaString;
var rgbaToHex = function (rgba) {
    var r = Math.round(rgba.r);
    var g = Math.round(rgba.g);
    var b = Math.round(rgba.b);
    var a = rgba.a !== undefined ? Math.round(rgba.a * 255) : 1;
    return "#".concat(r < 16 ? 0 : "").concat(r.toString(16)).concat(g < 16 ? 0 : "").concat(g.toString(16)).concat(b < 16 ? 0 : "").concat(b.toString(16)).concat(a < 16 ? 0 : "").concat(a.toString(16));
};
exports.rgbaToHex = rgbaToHex;
var rgbaStringToRgbaObject = function (rgba) {
    if (typeof rgba !== "string")
        return rgba;
    var rgbaArray = rgba.replace(/rgba?\(|\)|\s/g, "").split(",");
    var map = rgbaArray.map(function (value) {
        if (value.includes(".")) {
            var x = value.split(".");
            var divisor = x[1].length > 1 ? 100 : 10;
            return 0 + +x[1] / divisor;
        }
        return +value;
    });
    return { r: map[0], g: map[1], b: map[2], a: map[3] };
};
exports.rgbaStringToRgbaObject = rgbaStringToRgbaObject;
