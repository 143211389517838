"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
var react_1 = __importStar(require("react"));
var reactflow_1 = require("reactflow");
function DoubleSideHandle(props) {
    var handleStyleSideBottom = props.handleStyleSideBottom, handleStyleSideTop = props.handleStyleSideTop, handleStyleTop = props.handleStyleTop, handleStyleBottom = props.handleStyleBottom, handleStyleLeft = props.handleStyleLeft, handleStyleRight = props.handleStyleRight, handleStyleTopClass = props.handleStyleTopClass, handleStyleBottomClass = props.handleStyleBottomClass, handleStyleLeftClass = props.handleStyleLeftClass, handleStyleRightClass = props.handleStyleRightClass, handleClick = props.handleClick;
    var nodesConnectable = (0, reactflow_1.useStore)(function (s) { return s.nodesConnectable; });
    var HandleTopClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.TOP));
    }, [handleClick, nodesConnectable]);
    var HandleBottomClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.BOTTOM));
    }, [handleClick, nodesConnectable]);
    var HandleRightTopClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.RIGHT_TOP));
    }, [handleClick, nodesConnectable]);
    var HandleRightBottomClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.RIGHT_BOTTOM));
    }, [handleClick, nodesConnectable]);
    var HandleLeftTopClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.LEFT_TOP));
    }, [handleClick, nodesConnectable]);
    var HandleLeftBottomClick = (0, react_1.useCallback)(function () {
        nodesConnectable && (handleClick === null || handleClick === void 0 ? void 0 : handleClick(reactFlow_1.Handles.LEFT_BOTTOM));
    }, [handleClick, nodesConnectable]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleTopClass, style: handleStyleTop, type: "source", id: "top", position: reactflow_1.Position.Top, onClick: HandleTopClick }),
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleLeftClass, style: __assign(__assign({}, handleStyleSideTop), handleStyleLeft), type: "source", id: "leftTop", position: reactflow_1.Position.Left, onClick: HandleLeftTopClick }),
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleLeftClass, style: __assign(__assign({}, handleStyleSideBottom), handleStyleLeft), type: "source", id: "leftBottom", position: reactflow_1.Position.Left, onClick: HandleLeftBottomClick }),
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleRightClass, style: __assign(__assign({}, handleStyleSideBottom), handleStyleRight), type: "source", id: "rightBottom", position: reactflow_1.Position.Right, onClick: HandleRightBottomClick }),
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleRightClass, style: __assign(__assign({}, handleStyleSideTop), handleStyleRight), type: "source", id: "rightTop", position: reactflow_1.Position.Right, onClick: HandleRightTopClick }),
        react_1.default.createElement(reactflow_1.Handle, { className: handleStyleBottomClass, style: handleStyleBottom, type: "source", id: "bottom", position: reactflow_1.Position.Bottom, onClick: HandleBottomClick })));
}
exports.default = (0, react_1.memo)(DoubleSideHandle);
