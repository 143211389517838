"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PPTXNodeUtils_1 = require("@components/Workspace/Node/utils/PPTXNodeUtils");
var react_1 = require("react");
function usePPTXNode(_) {
    var customer = _.customer;
    var _a = (0, react_1.useState)(false), showPPTXView = _a[0], setShowPPTXView = _a[1];
    var onTogglePPTXView = (0, react_1.useCallback)(function () {
        setShowPPTXView(function (prev) { return !prev; });
    }, []);
    var _b = (0, react_1.useState)("structure"), PPTXView = _b[0], setPPTXView = _b[1];
    var onChangePPTXView = (0, react_1.useCallback)(function (v) {
        setPPTXView(v);
    }, []);
    var PPTXNode = (0, react_1.useMemo)(function () { return (0, PPTXNodeUtils_1.CreatePPTXNode)({ customer: customer, PPTXView: PPTXView }); }, [customer, PPTXView]);
    var PPTXExtent = (0, react_1.useMemo)(function () { return [
        [-PPTXNode.data.width, -PPTXNode.data.height],
        [PPTXNode.data.width * 2, PPTXNode.data.height * 2],
    ]; }, [PPTXNode]);
    return {
        showPPTXView: showPPTXView,
        PPTXNode: PPTXNode,
        PPTXView: PPTXView,
        PPTXExtent: PPTXExtent,
        onTogglePPTXView: onTogglePPTXView,
        onChangePPTXView: onChangePPTXView,
    };
}
exports.default = usePPTXNode;
