"use strict";
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
/* eslint-disable import/prefer-default-export */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCutCopyPaste = void 0;
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function getSelectedGraph(selectedNodes, elements) {
    var allEdges = [];
    var allNodeMap = {};
    elements.forEach(function (node) {
        if ((0, reactflow_1.isEdge)(node)) {
            allEdges.push(node);
        }
        else {
            allNodeMap[node.id] = node;
        }
    });
    var edgeMap = {};
    var selectedNodeIds = [];
    var nodes = [];
    selectedNodes.forEach(function (node) {
        if (!(0, reactflow_1.isEdge)(node)) {
            selectedNodeIds.push(node.id);
            var connectedEdges = (0, reactflow_1.getConnectedEdges)([node], allEdges);
            connectedEdges.forEach(function (edge) { return (edgeMap[edge.id] = edge); });
            nodes.push(allNodeMap[node.id]);
        }
    });
    // pick edges which has both nodes present in selectedNodes
    Object.values(edgeMap).forEach(function (edge) {
        if (selectedNodeIds.includes(edge.source) && selectedNodeIds.includes(edge.target)) {
            nodes.push(edge);
        }
    });
    return nodes;
}
function useCutCopyPaste(nodes, edges, onNodesDelete, onNodesChange, _addElements, mouse, rfInstance, contextAnchor) {
    var _this = this;
    var selectedNodes = nodes.filter(function (node) { return node.selected; });
    var getNodesToCutOrCopy = (0, react_1.useCallback)(function (elements) {
        var nodesToCut = __spreadArray([], elements, true);
        console.log(elements);
        var groupNodes = elements.filter(function (sn) { return sn.type === "customgroup"; });
        var _loop_1 = function (groupNode) {
            var children = nodes.filter(function (n) { return n.parentNode === groupNode.id; });
            nodesToCut.push.apply(nodesToCut, children);
        };
        for (var _i = 0, groupNodes_1 = groupNodes; _i < groupNodes_1.length; _i++) {
            var groupNode = groupNodes_1[_i];
            _loop_1(groupNode);
        }
        return nodesToCut;
    }, [nodes]);
    var formatDataForPasting = (0, react_1.useCallback)(function (isFromHotkey, nodesToCutOrCopy) {
        var formattedData = isFromHotkey
            ? JSON.stringify(getSelectedGraph(nodesToCutOrCopy, __spreadArray(__spreadArray([], nodes, true), edges, true)))
            : JSON.stringify(nodesToCutOrCopy);
        return formattedData;
    }, [nodes, edges]);
    var cut = (0, react_1.useCallback)(function (event, element) {
        if (element === void 0) { element = null; }
        if (selectedNodes.length > 0 || element) {
            var isFromHotkey = element === null;
            var elementsToCut = (isFromHotkey ? selectedNodes : [element]);
            var nodesToCut = getNodesToCutOrCopy(elementsToCut);
            var data = formatDataForPasting(isFromHotkey, nodesToCut);
            navigator.clipboard.writeText(data);
            onNodesDelete(nodesToCut);
            onNodesChange(nodesToCut.map(function (el) { return ({ id: el.id, type: "remove" }); }));
            event.preventDefault();
        }
    }, [
        selectedNodes,
        onNodesDelete,
        onNodesChange,
        formatDataForPasting,
        getNodesToCutOrCopy,
    ]);
    var copy = (0, react_1.useCallback)(function (event, element) {
        var _a, _b;
        if (element === void 0) { element = null; }
        // @ts-ignore
        if (typeof ((_a = event.target) === null || _a === void 0 ? void 0 : _a.value) === "string" || ((_b = event.target) === null || _b === void 0 ? void 0 : _b.id) === "nodeLabel") {
            return;
        }
        if (selectedNodes.length > 0 || element) {
            var isFromHotkey = element === null;
            var elementsToCopy = (isFromHotkey ? selectedNodes : [element]);
            var nodesToCopy = getNodesToCutOrCopy(elementsToCopy);
            var data = formatDataForPasting(isFromHotkey, nodesToCopy);
            navigator.clipboard.writeText(data);
            event.preventDefault();
        }
    }, [selectedNodes, getNodesToCutOrCopy, formatDataForPasting]);
    var handlePaste = function (elements) {
        var _a, _b;
        if (rfInstance) {
            var x = ((_a = mouse.clientX) !== null && _a !== void 0 ? _a : contextAnchor === null || contextAnchor === void 0 ? void 0 : contextAnchor.x);
            var y = ((_b = mouse.clientY) !== null && _b !== void 0 ? _b : contextAnchor === null || contextAnchor === void 0 ? void 0 : contextAnchor.y);
            var position_1 = rfInstance.screenToFlowPosition({
                x: x,
                y: y,
            });
            var sortedByY = __spreadArray([], JSON.parse(JSON.stringify(elements)), true).filter(function (e) { return (0, reactflow_1.isNode)(e); })
                .sort(function (a, b) { return b.position.y - a.position.y; });
            if (sortedByY.some(function (el) { return el.type === "customgroup"; })) {
                sortedByY = sortedByY.filter(function (e) { return !e.parentNode; });
            }
            var topNode_1 = sortedByY[sortedByY.length - 1];
            var mostLeftNode_1 = sortedByY.reduce(function (prev, current) {
                return prev.position.x < current.position.x ? prev : current;
            });
            var elementsToAdd = __spreadArray([], JSON.parse(JSON.stringify(elements)), true);
            var now_1 = Date.now();
            elementsToAdd.map(function (element) {
                var newEl = __assign({}, element);
                if ((0, reactflow_1.isEdge)(newEl)) {
                    newEl.id = "".concat(newEl.id, "_").concat(now_1, "-edit");
                    newEl.source = "".concat(newEl.source, "_").concat(now_1, "-edit");
                    newEl.target = "".concat(newEl.target, "_").concat(now_1, "-edit");
                }
                else {
                    newEl.id = "".concat(newEl.id, "_").concat(now_1, "-edit");
                    var xDistanceToTopNode = newEl.position.x - mostLeftNode_1.position.x;
                    var yDistanceToTopNode = newEl.position.y - topNode_1.position.y;
                    newEl.position.x = position_1.x + xDistanceToTopNode;
                    newEl.position.y = position_1.y + yDistanceToTopNode;
                }
                return newEl;
            });
            _addElements(elementsToAdd);
        }
    };
    var paste = function (event) { return __awaiter(_this, void 0, void 0, function () {
        var elementsToAdd, _a, _b, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    _b = (_a = JSON).parse;
                    return [4 /*yield*/, navigator.clipboard.readText()];
                case 1:
                    elementsToAdd = _b.apply(_a, [_c.sent()]);
                    event.preventDefault();
                    handlePaste(elementsToAdd);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        document.addEventListener("cut", cut);
        document.addEventListener("copy", copy);
        document.addEventListener("paste", paste);
        return function () {
            document.removeEventListener("cut", cut);
            document.removeEventListener("copy", copy);
            document.removeEventListener("paste", paste);
        };
    }, [nodes, edges, selectedNodes, cut, copy, paste]);
    return { cut: cut, copy: copy, paste: paste };
}
exports.useCutCopyPaste = useCutCopyPaste;
