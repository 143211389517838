"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var conditionConstants_1 = require("./conditionConstants");
var conditions_1 = require("@customTypes/reducers/conditions");
var constants_1 = require("../constants");
var constants_2 = require("@api/constants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var useEdge_1 = require("@hooks/condition/useEdge");
var initialState = {
    loading: false,
    conditions: [],
    label: "",
    description: "",
    group: "",
    groupOrLawsuit: "",
    nodeElements: [],
    edgeElements: [],
    groupsDropDownOptions: [],
    nodeAttributes: [],
    relationshipLabels: [],
    nodes: [],
    relationships: [],
    conditionTags: [],
    mouseLoading: false,
    conditionsDescendants: [],
    deletingFolder: 0,
    loadings: constants_2.initialLoadings,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case conditionConstants_1.GET_CONDITIONS_LOADING:
        case conditionConstants_1.CONDITION_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case conditionConstants_1.GET_CONDITIONS_SUCCESS:
            return __assign(__assign({}, state), { conditions: action.conditions, loading: false });
        case conditionConstants_1.GET_CONDITIONS_FAILED:
        case conditionConstants_1.CONDITION_DUPLICATE_FAILED:
            return __assign(__assign({}, state), { loading: false });
        case conditionConstants_1.POST_CONDITION_SUCCESS:
            return __assign(__assign({}, state), { label: "", description: "", groupOrLawsuit: "" });
        case conditionConstants_1.POST_CONDITION_FAILED:
        case conditionConstants_1.PUT_CONDITION_FAILED:
        case conditionConstants_1.SAVE_CONDITION_SUCCESS:
        case conditionConstants_1.SAVE_CONDITION_FAILED:
        case conditionConstants_1.SHOW_CONDITION_FAILED:
        case conditionConstants_1.GET_BUILD_TYPES_VALUES_FAILED:
        case conditionConstants_1.DELETE_CONDITION_FAILED:
        case conditionConstants_1.FORCE_DELETE_CONDITION_FAILED:
        case conditionConstants_1.GET_NODE_VALUES_FAILED:
        case conditionConstants_1.GET_RELATIONSHIP_VALUES_FAILED:
        case conditionConstants_1.CONDITION_POST_EDGE_FAILED:
        case conditionConstants_1.CONDITION_PUT_EDGE_FAILED:
        case conditionConstants_1.CONDITION_PUT_NODE_FAILED:
        case conditionConstants_1.CONDITION_POST_NODE_FAILED:
        case conditionConstants_1.GET_CONDITIONS_DESCENDANTS_FAILED:
        case conditionConstants_1.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED:
            return state;
        case conditionConstants_1.CONDITION_ADD_ELEMENTS_LOADING:
        case conditionConstants_1.POST_STANDARD_NODE_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case conditionConstants_1.CONDITION_ADD_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false, nodeElements: state.nodeElements.concat(action.nodes), edgeElements: state.edgeElements.concat(action.edges) });
        case conditionConstants_1.CONDITION_ADD_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case conditionConstants_1.PUT_CONDITION_SUCCESS:
            return state;
        case conditionConstants_1.SHOW_CONDITION_SUCCESS:
            return __assign(__assign({}, state), { label: action.label, description: action.description, groupOrLawsuit: action.groupOrLawsuit, nodeElements: action.nodes, edgeElements: action.edges, conditionTags: action.tags });
        case conditionConstants_1.GET_BUILD_TYPES_VALUES_SUCCESS:
            return __assign(__assign({}, state), { nodeAttributes: action.nodeAttributes, relationshipLabels: action.relationshipLabels });
        case conditionConstants_1.GET_NODE_VALUES_SUCCESS:
            return __assign(__assign({}, state), { nodes: action.nodes });
        case conditionConstants_1.GET_RELATIONSHIP_VALUES_SUCCESS:
            return __assign(__assign({}, state), { relationships: action.relationships });
        case conditionConstants_1.TITLE_CHANGE_CONDITION:
            return __assign(__assign({}, state), { label: action.title });
        case conditionConstants_1.DESCRIPTION_CHANGE_CONDITION:
            return __assign(__assign({}, state), { description: action.description });
        case conditionConstants_1.CONDITION_POST_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: __spreadArray(__spreadArray([], state.nodeElements, true), [action.node], false) });
        case conditionConstants_1.CONDITION_PUT_NODE_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.node.id) {
                        return action.node;
                    }
                    return node;
                }) });
        case conditionConstants_1.CONDITION_PUT_EDGE_SUCCESS:
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (edge) {
                    if (edge.id === action.edge.id) {
                        return action.edge;
                    }
                    return edge;
                }) });
        case conditionConstants_1.CONDITION_POST_EDGE_SUCCESS:
            return __assign(__assign(__assign({}, state), { edgeElements: __spreadArray(__spreadArray([], state.edgeElements, true), [action.edge], false) }), (action.node && {
                nodeElements: state.nodeElements.map(function (node) {
                    if (node.id === action.node.id) {
                        return action.node;
                    }
                    return node;
                }),
            }));
        case conditionConstants_1.DELETE_CONDITION_NODES_LOADING:
        case conditionConstants_1.DELETE_CONDITION_EDGES_LOADING:
            return __assign(__assign({}, state), { mouseLoading: true });
        case conditionConstants_1.DELETE_CONDITION_NODES_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false, label: action.label, description: action.description, groupOrLawsuit: action.groupOrLawsuit, nodeElements: action.nodes, edgeElements: action.edges, conditionTags: action.tags });
        case conditionConstants_1.DELETE_CONDITION_EDGES_SUCCESS:
            return __assign(__assign({}, state), { mouseLoading: false, label: action.label, description: action.description, groupOrLawsuit: action.groupOrLawsuit, nodeElements: action.nodes, edgeElements: action.edges, conditionTags: action.tags });
        case conditionConstants_1.DELETE_CONDITION_NODES_FAILED:
        case conditionConstants_1.DELETE_CONDITION_EDGES_FAILED:
        case conditionConstants_1.POST_STANDARD_NODE_FAILED:
            return __assign(__assign({}, state), { mouseLoading: false });
        case conditionConstants_1.CONDITION_RELATIONSHIP_ADD_TO_LIST:
            return __assign(__assign({}, state), { relationships: __spreadArray(__spreadArray([], state.relationships, true), [action.relationship], false) });
        case conditionConstants_1.CONDITION_NODE_ADD_TO_LIST:
            return __assign(__assign({}, state), { nodes: __spreadArray(__spreadArray([], state.nodes, true), [action.node], false) });
        case conditionConstants_1.CONDITION_NODE_ATTRIBUT_ADD_TO_LIST:
            return __assign(__assign({}, state), { nodeAttributes: __spreadArray(__spreadArray([], state.nodeAttributes, true), [action.attribut], false) });
        case conditionConstants_1.CHANGE_TAGS:
            return __assign(__assign({}, state), { conditionTags: action.tags });
        case conditionConstants_1.CHANGE_NODES:
            return __assign(__assign({}, state), { nodeElements: action.nodes });
        case conditionConstants_1.CHANGE_EDGES:
            return __assign(__assign({}, state), { edgeElements: action.edges });
        case conditionConstants_1.POST_STANDARD_NODE_SUCCESS: {
            return __assign(__assign({}, state), { nodes: __spreadArray(__spreadArray([], state.nodes, true), [action.node], false), mouseLoading: false });
        }
        case conditionConstants_1.DELETE_CONDITION_SUCCESS: {
            return __assign(__assign({}, state), { conditionsDescendants: action.conditionsDescendants });
        }
        case conditionConstants_1.GET_CONDITIONS_DESCENDANTS_SUCCESS: {
            return __assign(__assign({}, state), { conditionsDescendants: action.conditionsDescendants });
        }
        case conditionConstants_1.CLEAR_CONDITION_DESCENDANTS:
            return __assign(__assign({}, state), { conditionsDescendants: initialState.conditionsDescendants });
        case conditionConstants_1.ADD_DUMMY_RELATIONSHIP_EDGE: {
            var numOfEdges = state.edgeElements.length;
            action.edge.label = (0, constants_1.indexToLetter)(numOfEdges + 1);
            var updatedNodeElements = [];
            if (action.handle !== null) {
                updatedNodeElements = state.nodeElements.map(function (n) {
                    if (n.id === action.handle.nodeId.toString()) {
                        n.data.handles.push(action.handle);
                    }
                    return n;
                });
            }
            return __assign(__assign(__assign({}, state), { edgeElements: __spreadArray(__spreadArray([], state.edgeElements, true), [action.edge], false) }), (action.handle !== null && { nodeElements: updatedNodeElements }));
        }
        case conditionConstants_1.REMOVE_DUMMY_RELATIONSHIP_EDGE: {
            var nodeWithDummyHandle_1 = null;
            var edgeElements = state.edgeElements.filter(function (edge) {
                if (edge.id === useEdge_1.CONDITION_DUMMY_ID) {
                    if (edge.sourceHandle === useEdge_1.CONDITION_DUMMY_ID ||
                        edge.targetHandle === useEdge_1.CONDITION_DUMMY_ID) {
                        nodeWithDummyHandle_1 =
                            edge.sourceHandle === useEdge_1.CONDITION_DUMMY_ID ? edge.source : edge.target;
                    }
                    return false;
                }
                return true;
            });
            var nodeElements = state.nodeElements.map(function (n) {
                if (n.id === nodeWithDummyHandle_1) {
                    n.data.handles = n.data.handles.filter(function (h) { return h.id !== parseInt(useEdge_1.CONDITION_DUMMY_ID, 10); });
                }
                return n;
            });
            return __assign(__assign({}, state), { edgeElements: edgeElements, nodeElements: nodeElements });
        }
        case conditionConstants_1.ADD_ATTRIBUTE_CONDITION: {
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (n) {
                    if (n.id === action.nodeId) {
                        n.data.conditionValues.push({
                            attribut: {
                                label: null,
                            },
                            comparison_type: conditions_1.ComparisonTypes.IS_EQUAL_TO,
                            comparison_value: "",
                        });
                    }
                    return n;
                }) });
        }
        case conditionConstants_1.CHANGE_ATTRIBUTE_CONDITION: {
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (n) {
                    if (n.id === action.nodeId) {
                        if (action.changeType === "attribut") {
                            n.data.conditionValues[action.index][action.changeType].label =
                                action.value;
                        }
                        else {
                            // @ts-ignore
                            n.data.conditionValues[action.index][action.changeType] = action.value;
                            if (action.changeType === "comparison_type" &&
                                [
                                    conditions_1.ComparisonTypes.EXISTS,
                                    conditions_1.ComparisonTypes.DOES_NOT_EXIST,
                                    conditions_1.ComparisonTypes.ALL,
                                ].includes(action.value)) {
                                n.data.conditionValues[action.index].comparison_value = "";
                            }
                        }
                    }
                    return n;
                }), nodeAttributes: action.isNew
                    ? __spreadArray(__spreadArray([], state.nodeAttributes, true), [{ label: action.value, value: action.value }], false) : state.nodeAttributes });
        }
        case conditionConstants_1.DELETE_ATTRIBUTE_CONDITION: {
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (n) {
                    if (n.id === action.nodeId) {
                        n.data.conditionValues = n.data.conditionValues.filter(function (v, i) { return i !== action.index; });
                    }
                    return n;
                }) });
        }
        case conditionConstants_1.EDIT_EDGE_LABEL: {
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (e) {
                    if (e.id === action.edgeId) {
                        e.data.label = action.label;
                    }
                    return e;
                }) });
        }
        case conditionConstants_1.EDIT_EDGE_TYPE: {
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (e) {
                    if (e.id === action.edgeId) {
                        e.type = action.type;
                    }
                    return e;
                }) });
        }
        case conditionConstants_1.EDIT_EDGE_CONDITION_TYPE: {
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (e) {
                    if (e.id === action.edgeId) {
                        e.data.comparison_type = action.conditionType;
                    }
                    return e;
                }) });
        }
        case conditionConstants_1.EDIT_EDGE_CONDITION_VALUE: {
            return __assign(__assign({}, state), { edgeElements: state.edgeElements.map(function (e) {
                    if (e.id === action.edgeId) {
                        e.data.comparison_value = action.conditionValue;
                    }
                    return e;
                }) });
        }
        case conditionConstants_1.CONDITION_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { conditions: action.condition
                    ? __spreadArray(__spreadArray([], state.conditions, true), [action.condition], false) : state.conditions, loadings: constants_2.initialLoadings });
        case conditionConstants_1.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC:
            return __assign(__assign({}, state), { nodeElements: state.nodeElements.map(function (n) {
                    if (n.id === action.id) {
                        n.data.exitLevelForPwc = action.exitLevel;
                    }
                    return n;
                }) });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("conditions"):
        case (0, folderConstants_1.postFolderFailed)("conditions"):
        case (0, folderConstants_1.putFolderTitleFailed)("conditions"):
        case (0, folderConstants_1.putFolderLocationFailed)("conditions"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("conditions"):
        case (0, folderConstants_1.duplicateFolderFailed)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("conditions"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: constants_2.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("conditions"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadings] = true, _a)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("conditions"):
        case (0, folderConstants_1.putFolderLocationLoading)("conditions"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("conditions"):
        case (0, folderConstants_1.putFolderTitleLoading)("conditions"):
        case (0, folderConstants_1.postFolderLoading)("conditions"):
        case (0, folderConstants_1.duplicateFolderLoading)("conditions"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadings] = true, _b)) });
        case (0, folderConstants_1.postFolderSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: __spreadArray([action.folder], state.conditions, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: state.conditions.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: state.conditions.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: state.conditions.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("conditions"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, deletingFolder: action.folder.id, conditionsDescendants: action.descendants });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, deletingFolder: 0, conditions: state.conditions
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, deletingFolder: 0, conditions: state.conditions
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: state.conditions.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("conditions"):
            return __assign(__assign({}, state), { loadings: constants_2.initialLoadings, conditions: __spreadArray(__spreadArray([], action.folder, true), state.conditions, true) });
        default:
            return state;
    }
}
exports.default = reducer;
