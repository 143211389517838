"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAVE_CONDITION_NODE_POSITION_LOADING = exports.DELETE_CONDITION_EDGES_FAILED = exports.DELETE_CONDITION_EDGES_SUCCESS = exports.DELETE_CONDITION_EDGES_LOADING = exports.DELETE_CONDITION_NODES_FAILED = exports.DELETE_CONDITION_NODES_SUCCESS = exports.DELETE_CONDITION_NODES_LOADING = exports.CHANGE_EDGES = exports.CHANGE_NODES = exports.CHANGE_TAGS = exports.CHANGE_CONDITION_ROW = exports.DESCRIPTION_CHANGE_CONDITION = exports.TITLE_CHANGE_CONDITION = exports.CONDITION_NODE_ATTRIBUT_ADD_TO_LIST = exports.CONDITION_NODE_ADD_TO_LIST = exports.CONDITION_POST_EDGE_FAILED = exports.CONDITION_POST_EDGE_SUCCESS = exports.CONDITION_PUT_EDGE_FAILED = exports.CONDITION_PUT_EDGE_SUCCESS = exports.CONDITION_PUT_NODE_FAILED = exports.CONDITION_PUT_NODE_SUCCESS = exports.CONDITION_POST_NODE_FAILED = exports.CONDITION_POST_NODE_SUCCESS = exports.CONDITION_RELATIONSHIP_ADD_TO_LIST = exports.GET_RELATIONSHIP_VALUES_FAILED = exports.GET_RELATIONSHIP_VALUES_SUCCESS = exports.GET_NODE_VALUES_FAILED = exports.GET_NODE_VALUES_SUCCESS = exports.CONDITION_ADD_ELEMENTS_FAILED = exports.CONDITION_ADD_ELEMENTS_SUCCESS = exports.CONDITION_ADD_ELEMENTS_LOADING = exports.SAVE_CONDITION_FAILED = exports.SAVE_CONDITION_SUCCESS = exports.GET_CONDITIONS_DESCENDANTS_FAILED = exports.GET_CONDITIONS_DESCENDANTS_SUCCESS = exports.CLEAR_CONDITION_DESCENDANTS = exports.FORCE_DELETE_CONDITION_FAILED = exports.DELETE_CONDITION_FAILED = exports.DELETE_CONDITION_SUCCESS = exports.PUT_CONDITION_FAILED = exports.PUT_CONDITION_SUCCESS = exports.SHOW_CONDITION_FAILED = exports.SHOW_CONDITION_SUCCESS = exports.GET_BUILD_TYPES_VALUES_FAILED = exports.GET_BUILD_TYPES_VALUES_SUCCESS = exports.POST_CONDITION_FAILED = exports.POST_CONDITION_SUCCESS = exports.GET_CONDITIONS_FAILED = exports.GET_CONDITIONS_SUCCESS = exports.GET_CONDITIONS_LOADING = void 0;
exports.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED = exports.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC = exports.CONDITION_DUPLICATE_FAILED = exports.CONDITION_DUPLICATE_SUCCESS = exports.CONDITION_DUPLICATE_LOADING = exports.EDIT_EDGE_CONDITION_VALUE = exports.EDIT_EDGE_CONDITION_TYPE = exports.EDIT_EDGE_TYPE = exports.EDIT_EDGE_LABEL = exports.DELETE_ATTRIBUTE_CONDITION = exports.CHANGE_ATTRIBUTE_CONDITION = exports.ADD_ATTRIBUTE_CONDITION = exports.REMOVE_DUMMY_RELATIONSHIP_EDGE = exports.ADD_DUMMY_RELATIONSHIP_EDGE = exports.POST_STANDARD_NODE_FAILED = exports.POST_STANDARD_NODE_SUCCESS = exports.POST_STANDARD_NODE_LOADING = exports.SAVE_CONDITION_NODE_POSITION_FAILED = exports.SAVE_CONDITION_NODE_POSITION_SUCCESS = void 0;
// API
exports.GET_CONDITIONS_LOADING = "GET_CONDITIONS_LOADING";
exports.GET_CONDITIONS_SUCCESS = "GET_CONDITIONS_SUCCESS";
exports.GET_CONDITIONS_FAILED = "GET_CONDITIONS_FAILED";
exports.POST_CONDITION_SUCCESS = "POST_CONDITION_SUCCESS";
exports.POST_CONDITION_FAILED = "POST_CONDITION_FAILED";
exports.GET_BUILD_TYPES_VALUES_SUCCESS = "GET_BUILD_TYPES_VALUES_SUCCESS";
exports.GET_BUILD_TYPES_VALUES_FAILED = "GET_BUILD_TYPES_VALUES_FAILED";
exports.SHOW_CONDITION_SUCCESS = "SHOW_CONDITION_SUCCESS";
exports.SHOW_CONDITION_FAILED = "SHOW_CONDITION_FAILED";
exports.PUT_CONDITION_SUCCESS = "PUT_CONDITION_SUCCESS";
exports.PUT_CONDITION_FAILED = "PUT_CONDITION_FAILED";
exports.DELETE_CONDITION_SUCCESS = "DELETE_CONDITION_SUCCESS";
exports.DELETE_CONDITION_FAILED = "DELETE_CONDITION_FAILED";
exports.FORCE_DELETE_CONDITION_FAILED = "FORCE_DELETE_CONDITION_FAILED";
exports.CLEAR_CONDITION_DESCENDANTS = "CLEAR_CONDITION_DESCENDANTS";
exports.GET_CONDITIONS_DESCENDANTS_SUCCESS = "GET_CONDITIONS_DESCENDANTS_SUCCESS";
exports.GET_CONDITIONS_DESCENDANTS_FAILED = "GET_CONDITIONS_DESCENDANTS_FAILED";
exports.SAVE_CONDITION_SUCCESS = "SAVE_CONDITION_SUCCESS";
exports.SAVE_CONDITION_FAILED = "SAVE_CONDITION_FAILED";
exports.CONDITION_ADD_ELEMENTS_LOADING = "CONDITION_ADD_ELEMENTS_LOADING";
exports.CONDITION_ADD_ELEMENTS_SUCCESS = "CONDITION_ADD_ELEMENTS_SUCCESS";
exports.CONDITION_ADD_ELEMENTS_FAILED = "CONDITION_ADD_ELEMENTS_FAILED";
exports.GET_NODE_VALUES_SUCCESS = "GET_NODE_VALUES_SUCCESS";
exports.GET_NODE_VALUES_FAILED = "GET_NODE_VALUES_FAILED";
exports.GET_RELATIONSHIP_VALUES_SUCCESS = "GET_RELATIONSHIP_VALUES_SUCCESS";
exports.GET_RELATIONSHIP_VALUES_FAILED = "GET_RELATIONSHIP_VALUES_FAILED";
exports.CONDITION_RELATIONSHIP_ADD_TO_LIST = "CONDITION_RELATIONSHIP_ADD_TO_LIST";
exports.CONDITION_POST_NODE_SUCCESS = "CONDITION_POST_NODE_SUCCESS";
exports.CONDITION_POST_NODE_FAILED = "CONDITION_POST_NODE_FAILED";
exports.CONDITION_PUT_NODE_SUCCESS = "CONDITION_PUT_NODE_SUCCESS";
exports.CONDITION_PUT_NODE_FAILED = "CONDITION_PUT_NODE_FAILED";
exports.CONDITION_PUT_EDGE_SUCCESS = "CONDITION_PUT_EDGE_SUCCESS";
exports.CONDITION_PUT_EDGE_FAILED = "CONDITION_PUT_EDGE_FAILED";
exports.CONDITION_POST_EDGE_SUCCESS = "CONDITION_POST_EDGE_SUCCESS";
exports.CONDITION_POST_EDGE_FAILED = "CONDITION_POST_EDGE_FAILED";
exports.CONDITION_NODE_ADD_TO_LIST = "CONDITION_NODE_ADD_TO_LIST";
exports.CONDITION_NODE_ATTRIBUT_ADD_TO_LIST = "CONDITION_NODE_ATTRIBUT_ADD_TO_LIST";
exports.TITLE_CHANGE_CONDITION = "TITLE_CHANGE_CONDITION";
exports.DESCRIPTION_CHANGE_CONDITION = "DESCRIPTION_CHANGE_CONDITION";
exports.CHANGE_CONDITION_ROW = "CHANGE_CONDITION_ROW";
exports.CHANGE_TAGS = "CHANGE_TAGS";
exports.CHANGE_NODES = "CHANGE_NODES_CONDITION";
exports.CHANGE_EDGES = "CHANGE_EDGES_CONDITION";
exports.DELETE_CONDITION_NODES_LOADING = "DELETE_CONDITION_NODES_LOADING";
exports.DELETE_CONDITION_NODES_SUCCESS = "DELETE_CONDITION_NODES_SUCCESS";
exports.DELETE_CONDITION_NODES_FAILED = "DELETE_CONDITION_NODES_FAILED";
exports.DELETE_CONDITION_EDGES_LOADING = "DELETE_CONDITION_EDGES_LOADING";
exports.DELETE_CONDITION_EDGES_SUCCESS = "DELETE_CONDITION_EDGES_SUCCESS";
exports.DELETE_CONDITION_EDGES_FAILED = "DELETE_CONDITION_EDGES_FAILED";
exports.SAVE_CONDITION_NODE_POSITION_LOADING = "SAVE_CONDITION_NODE_POSITION_LOADING";
exports.SAVE_CONDITION_NODE_POSITION_SUCCESS = "SAVE_CONDITION_NODE_POSITION_SUCCESS";
exports.SAVE_CONDITION_NODE_POSITION_FAILED = "SAVE_CONDITION_NODE_POSITION_FAILED";
exports.POST_STANDARD_NODE_LOADING = "CONDITIONS_POST_STANDARD_NODE_LOADING";
exports.POST_STANDARD_NODE_SUCCESS = "CONDITIONS_POST_STANDARD_NODE_SUCCESS";
exports.POST_STANDARD_NODE_FAILED = "CONDITIONS_POST_STANDARD_NODE_FAILED";
exports.ADD_DUMMY_RELATIONSHIP_EDGE = "ADD_DUMMY_RELATIONSHIP_EDGE";
exports.REMOVE_DUMMY_RELATIONSHIP_EDGE = "REMOVE_DUMMY_RELATIONSHIP_EDGE";
exports.ADD_ATTRIBUTE_CONDITION = "ADD_ATTRIBUTE_CONDITION";
exports.CHANGE_ATTRIBUTE_CONDITION = "CHANGE_ATTRIBUTE_CONDITION";
exports.DELETE_ATTRIBUTE_CONDITION = "DELETE_ATTRIBUTE_CONDITION";
exports.EDIT_EDGE_LABEL = "EDIT_EDGE_LABEL";
exports.EDIT_EDGE_TYPE = "EDIT_EDGE_TYPE";
exports.EDIT_EDGE_CONDITION_TYPE = "EDIT_EDGE_CONDITION_TYPE";
exports.EDIT_EDGE_CONDITION_VALUE = "EDIT_EDGE_CONDITION_VALUE";
exports.CONDITION_DUPLICATE_LOADING = "CONDITION_DUPLICATE_LOADING";
exports.CONDITION_DUPLICATE_SUCCESS = "CONDITION_DUPLICATE_SUCCESS";
exports.CONDITION_DUPLICATE_FAILED = "CONDITION_DUPLICATE_FAILED";
exports.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC = "CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC";
exports.CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED = "CONDITION_NODE_TOGGLE_EXIT_LEVEL_PWC_FAILED";
