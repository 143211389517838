"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var data_1 = require("@customTypes/data");
var lawsuitConstants_1 = require("./lawsuitConstants");
var initLoadings = __assign(__assign({}, constants_1.initialLoadings), { dashboard: false, client: false, recommended: false, mentioned: false, sharedExternal: false, recent: false });
var initClient = {
    id: 0,
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    lawsuitId: 0,
    createdAt: "",
    updatedAt: "",
};
var initialState = {
    lawsuits: [],
    elements: data_1.INITIALELEMENTS,
    id: 0,
    title: "",
    description: "",
    caseNumber: "",
    caseValue: null,
    caseType: null,
    isFinished: false,
    group: "",
    users: [],
    usersDropDownOptions: [],
    lawsuitDropdownOptions: [],
    cvr: "",
    logo: "",
    recommendedEvents: [],
    mentionedElements: [],
    sharedExternalElements: [],
    recentElements: [],
    groupId: 0,
    loadings: initLoadings,
    client: initClient,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case lawsuitConstants_1.LAWSUIT_DUPLICATE_LOADING:
        case lawsuitConstants_1.GET_LAWSUIT_CLIENT_LOADING:
        case lawsuitConstants_1.POST_LAWSUIT_CLIENT_LOADING:
        case lawsuitConstants_1.PUT_LAWSUIT_CLIENT_LOADING:
        case lawsuitConstants_1.DELETE_LAWSUIT_CLIENT_LOADING:
        case lawsuitConstants_1.GET_RECOMMENDED_EVENTS_LOADING:
        case lawsuitConstants_1.GET_MENTIONED_ELEMENTS_LOADING:
        case lawsuitConstants_1.GET_SHARED_EXTERNAL_ELEMENTS_LOADING:
        case lawsuitConstants_1.GET_RECENT_ELEMENTS_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case lawsuitConstants_1.LAWSUIT_DUPLICATE_FAILED:
        case lawsuitConstants_1.GET_LAWSUIT_CLIENT_FAILED:
        case lawsuitConstants_1.POST_LAWSUIT_CLIENT_FAILED:
        case lawsuitConstants_1.PUT_LAWSUIT_CLIENT_FAILED:
        case lawsuitConstants_1.DELETE_LAWSUIT_CLIENT_FAILED:
        case lawsuitConstants_1.GET_LAWSUIT_CVR_FAILED:
        case lawsuitConstants_1.SHOW_LAWSUIT_LITE_FAILED:
        case lawsuitConstants_1.PUT_LAWSUIT_FAILED:
        case lawsuitConstants_1.DELETE_LAWSUIT_FAILED:
        case lawsuitConstants_1.POST_LAWSUIT_FAILED:
        case lawsuitConstants_1.GET_RECOMMENDED_EVENTS_FAILED:
        case lawsuitConstants_1.GET_MENTIONED_ELEMENTS_FAILED:
        case lawsuitConstants_1.GET_SHARED_EXTERNAL_ELEMENTS_FAILED:
        case lawsuitConstants_1.GET_RECENT_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { loadings: initLoadings });
        case lawsuitConstants_1.LAWSUIT_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { lawsuits: action.lawsuit ? __spreadArray([action.lawsuit], state.lawsuits, true) : state.lawsuits, loadings: initLoadings });
        case lawsuitConstants_1.POST_LAWSUIT_LOADING: {
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: true }) });
        }
        case lawsuitConstants_1.GET_LAWSUITS_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: true }) });
        case lawsuitConstants_1.GET_LAWSUITS_SUCCESS:
            return __assign(__assign({}, state), { lawsuits: action.lawsuits, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lawsuitConstants_1.CREATE_NEW_LAWSUIT:
        case lawsuitConstants_1.POST_LAWSUIT_SUCCESS:
            return __assign(__assign(__assign({}, state), ("lawsuit" in action && { lawsuits: __spreadArray([action.lawsuit], state.lawsuits, true) })), { title: "", description: "", caseNumber: "", caseValue: null, caseType: null, isFinished: false, users: [], loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case lawsuitConstants_1.SHOW_LAWSUIT_LOADING:
            return __assign(__assign({}, state), { title: "", description: "", caseNumber: "", caseValue: null, caseType: null, isFinished: false, elements: data_1.INITIALELEMENTS, loadings: __assign(__assign({}, state.loadings), { main: true }) });
        case lawsuitConstants_1.SHOW_LAWSUIT_SUCCESS:
            return __assign(__assign({}, state), { title: action.title, description: action.description, caseNumber: action.caseNumber, elements: action.elements, groupId: action.groupId, caseValue: action.caseValue, isFinished: action.isFinished, caseType: action.lawsuitType, id: action.id, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lawsuitConstants_1.SHOW_LAWSUIT_LITE_SUCCESS:
            return __assign(__assign({}, state), { id: action.id, title: action.title });
        case lawsuitConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.item });
        case lawsuitConstants_1.CASE_NUMBER_CHANGE:
            return __assign(__assign({}, state), { caseNumber: action.item });
        case lawsuitConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.item });
        case lawsuitConstants_1.CASE_VALUE_CHANGE:
            return __assign(__assign({}, state), { caseValue: action.value });
        case lawsuitConstants_1.CASE_TYPE_CHANGE:
            return __assign(__assign({}, state), { caseType: action.value });
        case lawsuitConstants_1.CASE_IS_FINISHED_CHANGE:
            return __assign(__assign({}, state), { isFinished: action.value });
        case lawsuitConstants_1.GET_LAWSUIT_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { lawsuitDropdownOptions: action.lawsuits });
        case lawsuitConstants_1.SHOW_LAWSUIT_FAILED:
        case lawsuitConstants_1.GET_LAWSUITS_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lawsuitConstants_1.DELETE_LAWSUIT_SUCCESS:
            return __assign(__assign({}, state), { loadings: initLoadings, lawsuits: state.lawsuits.filter(function (lawsuit) { return lawsuit.id !== action.id; }) });
        case lawsuitConstants_1.GET_LAWSUIT_CVR_LOADING:
            return __assign(__assign({}, state), { cvr: "", loadings: __assign(__assign({}, state.loadings), { main: true }) });
        case lawsuitConstants_1.GET_LAWSUIT_CVR_SUCCESS:
            return __assign(__assign({}, state), { cvr: action.data.cvr, logo: action.data.logo, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lawsuitConstants_1.GET_LAWSUIT_CLIENT_SUCCESS:
            return __assign(__assign({}, state), { client: action.client || initClient, loadings: __assign(__assign({}, state.loadings), { client: false }) });
        case lawsuitConstants_1.POST_LAWSUIT_CLIENT_SUCCESS:
            return __assign(__assign({}, state), { client: action.client, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case lawsuitConstants_1.PUT_LAWSUIT_CLIENT_SUCCESS:
            return __assign(__assign({}, state), { client: action.client, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case lawsuitConstants_1.DELETE_LAWSUIT_CLIENT_SUCCESS:
            return __assign(__assign({}, state), { client: initClient, loadings: __assign(__assign({}, state.loadings), { client: false }) });
        case lawsuitConstants_1.CHANGE_LAWSUIT_CLIENT:
            return __assign(__assign({}, state), { client: __assign(__assign({}, state.client), (_b = {}, _b[action.key] = action.value, _b)) });
        case lawsuitConstants_1.GET_RECOMMENDED_EVENTS_SUCCESS:
            return __assign(__assign({}, state), { recommendedEvents: action.events, loadings: __assign(__assign({}, state.loadings), { recommended: false }) });
        case lawsuitConstants_1.CLEAR_RECOMMENDED_EVENTS:
            return __assign(__assign({}, state), { recommendedEvents: [], loadings: __assign(__assign({}, state.loadings), { recommended: false }) });
        case lawsuitConstants_1.GET_MENTIONED_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { mentionedElements: action.events, loadings: __assign(__assign({}, state.loadings), { mentioned: false }) });
        case lawsuitConstants_1.GET_RECENT_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { recentElements: action.events, loadings: __assign(__assign({}, state.loadings), { recent: false }) });
        case lawsuitConstants_1.GET_SHARED_EXTERNAL_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { sharedExternalElements: action.events, loadings: __assign(__assign({}, state.loadings), { sharedExternal: false }) });
        case lawsuitConstants_1.CLEAR_MENTIONED_ELEMENTS:
            return __assign(__assign({}, state), { mentionedElements: [], loadings: __assign(__assign({}, state.loadings), { mentioned: false }) });
        case lawsuitConstants_1.CLEAR_LAWSUITS:
            return __assign(__assign({}, state), { lawsuits: [], loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lawsuitConstants_1.CLEAR_LAWSUIT_DROPDOWNS:
            return __assign(__assign({}, state), { lawsuitDropdownOptions: [], loadings: __assign(__assign({}, state.loadings), { main: false }) });
        default:
            return state;
    }
}
exports.default = reducer;
