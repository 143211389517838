"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitialCondition = exports.getInitialTrigger = exports.getInitialHandler = void 0;
var date_fns_1 = require("date-fns");
var handlerMapping = {
    assign_person: { type: "assign_person", column: null, person: null, replace: false },
    change_status: { type: "change_status", column: null, labelId: null },
    clear_column: { type: "clear_column", column: null },
    create_group: { type: "create_group", title: null },
    create_task: { type: "create_task" },
    delete_task: { type: "delete_task" },
    duplicate_task: { type: "duplicate_task" },
    duplicate_group: { type: "duplicate_group", groupId: null },
    increase_decrease_number: {
        type: "increase_decrease_number",
        amount: null,
        column: null,
        decrease: false,
    },
    move_task_to_group: { type: "move_task_to_group", groupId: null },
    new_handler: { type: "new_handler" },
    notify: { type: "notify", message: null, person: null },
    push_date: { type: "push_date", column: null, incrementation: null, unit: null },
    set_date_to_today: { type: "set_date_to_today", column: null },
};
function getInitialHandler(type) {
    return handlerMapping[type];
}
exports.getInitialHandler = getInitialHandler;
var defaultTime = (0, date_fns_1.parse)("07:00", "HH:mm", new Date());
var getUTCOffset = function () {
    var now = new Date();
    return now.getTimezoneOffset();
};
var triggerMapping = {
    column_change: { type: "column_change", column: null },
    date_arrives: {
        type: "date_arrives",
        column: null,
        time: defaultTime,
        scheduledOccurrence: null,
        temporalRelationship: "after",
        unit: "day",
        utcOffset: getUTCOffset(),
    },
    date_passed: { type: "date_passed", column: null },
    person_assigned: { type: "person_assigned", column: null },
    status_change: { type: "status_change", column: null, to: null },
    status_change_from_something: {
        type: "status_change_from_something",
        column: null,
        from: null,
        to: null,
    },
    subtask_created: { type: "subtask_created" },
    task_created: { type: "task_created" },
    task_moved_to_group: { type: "task_moved_to_group", groupId: null },
    time_period: {
        type: "time_period",
        days: [],
        scheduledOccurrence: null,
        time: defaultTime,
        unit: "day",
        weekdays: [],
        utcOffset: getUTCOffset(),
    },
};
function getInitialTrigger(type) {
    return triggerMapping[type];
}
exports.getInitialTrigger = getInitialTrigger;
var conditionMapping = {
    dropdown_meets_condition: {
        type: "dropdown_meets_condition",
        column: null,
        dropdownOperator: "contains_all",
        values: [],
    },
    empty_column: { type: "empty_column", checkEmpty: true, column: null },
    new_condition: { type: "new_condition" },
    number_meets_condition: {
        type: "number_meets_condition",
        amount: null,
        column: null,
        numberOperator: "gt",
    },
    specified_person: { type: "specified_person", column: null, userId: null },
    specified_status: {
        type: "specified_status",
        column: null,
        isEqual: true,
        labelId: null,
    },
    specified_task_group: { type: "specified_task_group", groupId: null },
};
function getInitialCondition(type) {
    return conditionMapping[type];
}
exports.getInitialCondition = getInitialCondition;
