"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DUMMY_NODE = void 0;
var conditionActions_1 = require("@pages/Conditions/reducers/conditionActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var notificationActions_1 = require("@redux/actions/notificationActions");
exports.DUMMY_NODE = {
    value: 0,
    label: "",
};
var useNode = function (dispatch, user, plan_id, t, rfInstance, reactFlowDimensions, id, nodes, nodeElements) {
    var location = (0, react_router_dom_1.useLocation)();
    var _a = (0, react_1.useState)(false), isUpdatingNode = _a[0], setIsUpdatingNode = _a[1];
    var _b = (0, react_1.useState)(""), nodeLabel = _b[0], setNodeLabel = _b[1];
    var _c = (0, react_1.useState)(exports.DUMMY_NODE), selectedStandardNodeOption = _c[0], setSelectedStandardNodeOption = _c[1];
    var _d = (0, react_1.useState)(null), standardNodeId = _d[0], setStandardNodeId = _d[1];
    (0, react_1.useEffect)(function () {
        if (nodeLabel !== "") {
            var node = nodes.find(function (n) { return n.label === nodeLabel; });
            if (node) {
                setSelectedStandardNodeOption({
                    value: node.id,
                    label: node.label,
                });
            }
        }
        if (standardNodeId !== null) {
            var node = nodes.find(function (n) { return n.id === standardNodeId; });
            if (node) {
                setSelectedStandardNodeOption({
                    value: node.id,
                    label: node.label,
                });
            }
        }
    }, [standardNodeId, nodes, nodeLabel]);
    var handleChangeStandardNode = function (option) {
        setNodeLabel((option === null || option === void 0 ? void 0 : option.label) || "");
        if (option === null) {
            // setNode(null);
            return;
        }
        if (option.__isNew__ && plan_id !== 1) {
            if (option.__isNew__) {
                dispatch((0, conditionActions_1.postStandardNode)(user, location, option.label, setStandardNodeId));
            }
            return;
        }
        if (option.__isNew__ && plan_id === 1) {
            dispatch((0, notificationActions_1.showNotification)("error", t("workspaces.you_can_not_create_new_item_types")));
        }
        else {
            setStandardNodeId(option.value);
        }
    };
    var closeNode = (0, react_1.useCallback)(function () {
        setIsUpdatingNode(false);
        setNodeLabel("");
        setSelectedStandardNodeOption(exports.DUMMY_NODE);
        setStandardNodeId(null);
    }, []);
    var handleNodeSave = (0, react_1.useCallback)(function (position) {
        var x = position.x, y = position.y;
        var choosenNode = nodes.find(function (r) { return r.label === nodeLabel; });
        if (choosenNode) {
            dispatch((0, conditionActions_1.postNode)(user, id, choosenNode.id, choosenNode.label, [], x, y, closeNode));
        }
    }, [rfInstance, id, nodeLabel]);
    var handlePutNode = function (conditionNodeId) {
        var nodeValues = nodeElements.find(function (x) { return x.id === conditionNodeId; })
            .data.conditionValues;
        dispatch((0, conditionActions_1.putNode)(user, +conditionNodeId, nodeValues));
    };
    var onNodeClick = (0, react_1.useCallback)(function (node) {
        setIsUpdatingNode(true);
        setNodeLabel(node.data.label);
        var standardNode = nodes.find(function (r) { return r.label === node.data.label; });
        setStandardNodeId((standardNode === null || standardNode === void 0 ? void 0 : standardNode.id) || null);
    }, [nodes]);
    return {
        onNodeClick: onNodeClick,
        handleNodeSave: handleNodeSave,
        handlePutNode: handlePutNode,
        setIsUpdatingNode: setIsUpdatingNode,
        closeNode: closeNode,
        nodeLabel: nodeLabel,
        isUpdatingNode: isUpdatingNode,
        selectedStandardNodeOption: selectedStandardNodeOption,
        handleChangeStandardNode: handleChangeStandardNode,
        standardNodeId: standardNodeId,
    };
};
exports.default = useNode;
