"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePPTXNode = void 0;
var constants_1 = require("@api/constants");
function GetPPTXNodeDimensions(customer) {
    switch (customer) {
        case constants_1.CUSTOMER.PWC:
            return {
                width: 1056,
                height: 816,
            };
        case constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG:
            return {
                width: 1280,
                height: 720,
            };
        default:
            return {
                width: 960,
                height: 540,
            };
    }
}
function CreatePPTXNode(data) {
    var dimensions = GetPPTXNodeDimensions(data.customer);
    return __assign({ id: "powerpoint", type: "powerpoint", selectable: false, draggable: false, connectable: false, deletable: false, focusable: false, style: { pointerEvents: "none" }, data: __assign(__assign({}, dimensions), data), position: { x: 0, y: 0 } }, dimensions);
}
exports.CreatePPTXNode = CreatePPTXNode;
