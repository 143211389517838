"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    bundlePanel: {
        padding: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginRight: "8px",
    },
    metaPaper: {
        position: "absolute",
        zIndex: 1000,
        backgroundColor: Colors_1.otherGenericColors.panel,
        left: 10,
        top: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        p: 0,
        maxWidth: "60%",
        maxHeight: 48,
    },
    viewsWrapper: __assign({ overflowX: "auto" }, (0, styles_1.JuristicScroll)(theme, { isHorizontal: true, size: 0.2 })),
    br2: {
        borderRadius: theme.spacing(1),
    },
    collaborationPaper: {
        position: "absolute",
        zIndex: 1000,
        backgroundColor: "#fff",
        right: 10,
        top: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ccc",
        width: "100%",
        marginBottom: 10,
    },
    typePopperPaper: {
        backgroundColor: "#fcfcfc",
        padding: 8,
        maxHeight: "70vh",
        overflowY: "hidden",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: "calc(1em + ".concat(theme.spacing(4), ")"),
        transition: theme.transitions.create("width"),
        width: "100%",
    },
    filterChipContainer: {
        marginTop: 10,
    },
    filterHeaders: {
        fontWeight: "bold",
        marginTop: 15,
    },
    chipIconContainer: {
        display: "flex",
        alignItems: "center",
    },
    tag: {
        width: 20,
        height: 20,
        borderRadius: 10,
    },
    filterDone: {
        width: 20,
    },
    filterChip: {
        margin: 4,
        borderRadius: 10,
    },
    countContainer: {
        padding: 2,
        paddingRight: 8,
        paddingLeft: 8,
        backgroundColor: theme.palette.background.default,
    },
    tagCount: {
        color: theme.palette.info.main,
    },
    itemsPaper: {
        position: "absolute",
        maxHeight: "75%",
        zIndex: 1000,
        backgroundColor: Colors_1.otherGenericColors.panel,
        "-webkit-transition": "left 0.3s ease-in-out",
        "-moz-transition": "left 0.3s ease-in-out",
        "-o-transition": "left 0.3s ease-in-out",
        transition: "left 0.3s ease-in-out",
        left: 10,
        margin: 0,
        top: " 50%",
        msTransform: "translateY(-50%)",
        transform: "translateY(-50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        pointerEvents: "auto",
    },
    framePanelOpenItemsEffect: {
        "-webkit-transition": "left 0.3s ease-in-out",
        "-moz-transition": "left 0.3s ease-in-out",
        "-o-transition": "left 0.3s ease-in-out",
        transition: "left 0.3s ease-in-out",
        left: 370,
    },
    framePanelOpenItemsEffectExpanded: {
        "-webkit-transition": "left 0.3s ease-in-out",
        "-moz-transition": "left 0.3s ease-in-out",
        "-o-transition": "left 0.3s ease-in-out",
        transition: "left 0.3s ease-in-out",
        left: 720,
    },
    framePanel: {
        position: "absolute",
        zIndex: 1000,
        backgroundColor: Colors_1.otherGenericColors.panel,
        left: 10,
        top: 68,
        height: "calc(100% - 136px)",
        "-webkit-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-moz-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-o-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transition: "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        width: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        pointerEvents: "auto",
        padding: 10,
        opacity: "0",
        visibility: "hidden",
    },
    framePanelOpen: {
        "-webkit-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-moz-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-o-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transition: "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        width: 350,
        opacity: "1",
        visibility: "visible",
    },
    framePanelExpanded: {
        "-webkit-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-moz-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-o-transition": "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transition: "width 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        width: 700,
        opacity: "1",
        visibility: "visible",
    },
    framePanelDivider: {
        height: 1,
        width: "100%",
    },
    panelHeaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: 5,
    },
    framePanelHeader: {
        fontWeight: "bold",
    },
    framePanelBody: __assign({ height: "100%", width: "100%", marginBottom: 10, paddingRight: 5, overflowY: "scroll" }, (0, styles_1.JuristicScroll)(theme)),
    genericScrollbar: __assign({ overflowY: "scroll" }, (0, styles_1.JuristicScroll)(theme)),
    framePanelBodyLoader: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    addSlideContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    addSlideButton: {
        backgroundColor: theme.palette.background.default,
    },
    slide: {
        width: "100%",
        display: "flex",
    },
    slideCloseable: {
        "-webkit-transition": "height 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-moz-transition": "height 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        "-o-transition": "height 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        transition: "height 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out",
        opacity: 0,
        height: 0,
        visibility: "hidden",
    },
    slideOpen: {
        opacity: 1,
        height: 69,
        visibility: "visible",
    },
    slideTitle: {
        width: "100%",
        backgroundColor: "#fff",
    },
    slideTextContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    framePanelButtonGroup: {
        display: "flex",
        gap: 20,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
    },
    topButtonContainer: {
        width: "100%",
        marginTop: 15,
    },
    viewsPaper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        /*
        position: "absolute",
        zIndex: 1000,
        backgroundColor: otherGenericColors.panel,
        left: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        top: "calc(50% - 200px)",
        pointerEvents: "auto"
        */
        maxHeight: "75%",
        pointerEvents: "auto",
    },
    controlsPaperAbsolute: {
        position: "absolute",
        zIndex: 1000,
        right: 10,
        bottom: 10,
        maxWidth: "45%",
    },
    controlsPaper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        pointerEvents: "auto",
    },
    shapeNodeButton: {
        "&:hover": {
            svg: { color: theme.palette.primary.main },
        },
    },
    buttons: {
        "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
        },
        fontSize: 26,
    },
    shapeNode: {
        fontSize: 18,
    },
    menuItem: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&:hover > div": {
            color: theme.palette.primary.main,
        },
    },
    activeButton: {
        color: theme.palette.primary.main,
    },
    horDivider: {
        margin: 10,
        height: 1,
    },
    horDividerNoMargin: {
        height: 1,
    },
    verDivder: {
        margin: 10,
    },
    logo: {
        width: 50,
        marginLeft: 12,
        userSelect: "none",
    },
    smallerIcon: {
        fontSize: 18,
    },
    shortCutText: {},
    shortCut: {
        fontWeight: 700,
    },
    shortCutRow: {
        marginTop: 5,
    },
    divider: {
        marginBottom: 10,
    },
    shortCutSectionHeader: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 5,
    },
    userName: {
        fontSize: 16,
    },
    userRole: {
        fontSize: 12,
        color: "gray",
    },
    avatar: { width: 30, height: 30 },
    shareButton: {
        borderRadius: 4,
        padding: "2px 6px",
        margin: "0 12px",
        height: 30,
        alignSelf: "center",
    },
    swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: 4,
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        marginTop: 15,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    color: {
        height: "14px",
        borderRadius: "2px",
        margin: "0px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
    },
    popover: {
        position: "absolute",
        zIndex: 9999,
    },
    cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
    iconsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },
    field: {
        width: "100%",
        marginBottom: 10,
    },
    fieldBasic: {
        width: "100%",
        marginBottom: 20,
        marginTop: 10,
    },
    inlineWrap: {
        display: "flex",
        flexDirection: "column",
    },
    buttonInit: {
        margin: theme.spacing(4),
        textAlign: "center",
    },
    colorLabelContainer: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
    },
    noMargin: {
        margin: 0,
    },
    colorPickerPosition: {
        bottom: 10,
    },
    attributeRow: {
        display: "flex",
        width: "100%",
    },
    tabPaperAbsolute: {
        position: "absolute",
        zIndex: 1000,
        left: 10,
        bottom: 10,
    },
    splitter: {
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderColor: "rgba(0,0,0,0.15)",
        borderRadius: 0,
        marginTop: "8px",
        marginBottom: "8px",
        fontWeight: 700,
    },
    transparentBackground: {
        backgroundColor: "transparent",
    },
    bundleDocumentDragUnderline: {
        background: "transparent !important",
        "&.Mui-disabled": {
            "&::before": {
                borderBottom: "none !important",
            },
        },
        "&::before": {
            left: "10px",
            right: "3px",
            borderBottom: "1px solid #ccc",
        },
        "&::after": {
            left: "10px",
            right: "3px",
        },
    },
    bundleDocumentDragTextField: {
        "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: 1,
            backgroundColor: "transparent",
            "&.Mui-disabled": {
                WebkitTextFillColor: "black",
                cursor: "default",
                paddingTop: "8px",
            },
            paddingY: "10px",
            paddingLeft: "14px",
        },
        margin: 0,
        backgroundColor: "transparent !important",
    },
    bundleDragItemInputLabelFix: {
        "& .MuiInputLabel-root": {
            transform: "translate(12px, 25px) scale(1)",
            "&[data-shrink='true']": {
                transform: "translate(12px, 7px) scale(0.75)",
            },
        },
    },
}); });
exports.default = useStyles;
