"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_hotkeys_hook_1 = require("react-hotkeys-hook");
var react_router_dom_1 = require("react-router-dom");
function useWorkspaceHotKeys(setShowContextMenu, handlePostSticky, handleVisabilityChange, setSnapToGrid, rfInstance, contextNode, handleShowNodeRelations, getAddressData, handleOpenCvr, id, undo, redo, toggleHelperLines) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    // indsæt element
    (0, react_hotkeys_hook_1.useHotkeys)(["cmd+i", "ctrl+i"], function () {
        setShowContextMenu(false);
    });
    // indsæt note
    (0, react_hotkeys_hook_1.useHotkeys)("n", handlePostSticky, {}, [handlePostSticky]);
    // open company data modal
    (0, react_hotkeys_hook_1.useHotkeys)("alt+c", handleOpenCvr, {}, [handleOpenCvr]);
    // go to analysis
    (0, react_hotkeys_hook_1.useHotkeys)("alt+a", function () { return navigate("analysis/".concat(id)); }, {}, [handleOpenCvr]);
    // show lines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+g", handleVisabilityChange, {}, [handleVisabilityChange]);
    // snap
    (0, react_hotkeys_hook_1.useHotkeys)("alt+k", function () {
        setSnapToGrid(function (prevVal) { return !prevVal; });
    });
    // helperLines
    (0, react_hotkeys_hook_1.useHotkeys)("alt+h", function () {
        toggleHelperLines();
    });
    // fit to view
    (0, react_hotkeys_hook_1.useHotkeys)("alt+1", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
    }, {}, [rfInstance]);
    // zoom to 100
    (0, react_hotkeys_hook_1.useHotkeys)("alt+0", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomTo(1);
    }, {}, [rfInstance]);
    // zoom out
    (0, react_hotkeys_hook_1.useHotkeys)("alt+-", function () {
        rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomOut();
    }, {}, [rfInstance]);
    // zoom in
    (0, react_hotkeys_hook_1.useHotkeys)("alt-*", function (e) {
        if (e.key === "±" || e.key === "+") {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.zoomIn();
        }
    }, { combinationKey: "-" }, [rfInstance]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+r", function () {
        contextNode && handleShowNodeRelations(contextNode);
    }, {}, [contextNode, handleShowNodeRelations]);
    (0, react_hotkeys_hook_1.useHotkeys)("alt+e", function () {
        contextNode && getAddressData(contextNode.id);
    }, {}, [contextNode]);
    (0, react_hotkeys_hook_1.useHotkeys)(["ctrl+z", "meta+z"], function () {
        undo.isPossible && undo();
    }, [undo]);
    (0, react_hotkeys_hook_1.useHotkeys)(["meta+shift+z", "ctrl+shift+z", "ctrl+y"], function () {
        redo.isPossible && redo();
    }, [redo]);
    // node specific
}
exports.default = useWorkspaceHotKeys;
