"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMultiSelected = exports.isMultiSelecting = exports.setIsMultiSelected = exports.setIsMultiSelecting = exports.useMultiSelect = void 0;
var react_1 = require("react");
var react_singleton_hook_1 = require("react-singleton-hook");
var isMultiSelectingInitState = false;
var isMultiSelectedInitState = false;
var isMultiSelectingState = isMultiSelectingInitState;
var globalIsMultiSelecting = function () {
    throw new Error("You must useMultiSelect before setting its state");
};
var isMultiSelectedState = isMultiSelectedInitState;
var globalIsMultiSelected = function () {
    throw new Error("You must useMultiSelect before setting its state");
};
exports.useMultiSelect = (0, react_singleton_hook_1.singletonHook)(isMultiSelectingInitState, function () {
    var _a = (0, react_1.useState)(isMultiSelectingInitState), _isMultiSelecting = _a[0], setIsMultiSelecting = _a[1];
    var _b = (0, react_1.useState)(isMultiSelectedInitState), _isMultiSelected = _b[0], setIsMultiSelected = _b[1];
    globalIsMultiSelecting = setIsMultiSelecting;
    globalIsMultiSelected = setIsMultiSelected;
    isMultiSelectingState = _isMultiSelecting;
    isMultiSelectedState = _isMultiSelected;
    return _isMultiSelecting;
});
var setIsMultiSelecting = function (bool) {
    return globalIsMultiSelecting(bool);
};
exports.setIsMultiSelecting = setIsMultiSelecting;
var setIsMultiSelected = function (bool) {
    return globalIsMultiSelected(bool);
};
exports.setIsMultiSelected = setIsMultiSelected;
var isMultiSelecting = function () { return isMultiSelectingState; };
exports.isMultiSelecting = isMultiSelecting;
var isMultiSelected = function () { return isMultiSelectedState; };
exports.isMultiSelected = isMultiSelected;
