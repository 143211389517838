"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialMyWork = exports.BoardDragLists = exports.initialBoard = void 0;
var lawsuits_1 = require("./lawsuits");
exports.initialBoard = {
    title: "",
    description: "",
    itemWidth: 300,
    isManuel: false,
    lawsuitId: null,
    groupId: null,
    groupOrLawsuit: lawsuits_1.INITIALLAWSUIT,
    tags: [],
    completed: false,
};
var BoardDragLists;
(function (BoardDragLists) {
    BoardDragLists["TASK_GROUPS"] = "taskGroups";
})(BoardDragLists = exports.BoardDragLists || (exports.BoardDragLists = {}));
exports.initialMyWork = {
    past: [],
    today: [],
    thisWeek: [],
    nextWeek: [],
    thisMonth: [],
    later: [],
    withoutDate: [],
};
