"use strict";
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleExport = exports.s2ab = exports.ExportType = void 0;
/* eslint-disable new-cap */
/* eslint-disable import/prefer-default-export */
var jspdf_1 = require("jspdf");
var use_react_screenshot_1 = require("use-react-screenshot");
var html_to_image_1 = require("html-to-image");
var reactflow_1 = require("reactflow");
var imageWidth = 1024;
var imageHeight = 768;
var ExportType;
(function (ExportType) {
    ExportType["Image"] = "image";
    ExportType["Pdf"] = "pdf";
})(ExportType = exports.ExportType || (exports.ExportType = {}));
var s2ab = function (s) {
    var buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    var view = new Uint8Array(buf); // create uint8array as viewer
    for (var i = 0; i < s.length; i++)
        view[i] = s.charCodeAt(i) & 0xff; // convert to octet
    return buf;
};
exports.s2ab = s2ab;
var download = function (_image, type, label, format, stopLoading) {
    if (!label) {
        label = "download";
    }
    if (type === ExportType.Image) {
        var a = document.createElement("a");
        a.href = _image;
        a.download = (0, use_react_screenshot_1.createFileName)("jpg", label);
        a.click();
    }
    else {
        var orientation = format[0] > format[1] ? "l" : "p";
        var doc = new jspdf_1.jsPDF({
            orientation: orientation,
            unit: "px",
            format: format,
        });
        doc.addImage(_image, "JPEG", 0, 0, format[0], format[1]);
        doc.save("".concat(label, ".pdf"));
    }
    stopLoading && stopLoading();
};
var handleExport = function (type, getNodes, label, stopLoading) {
    var currTarget = document.querySelector(".react-flow__viewport");
    if (currTarget && getNodes) {
        var width_1 = imageWidth;
        var height_1 = imageHeight;
        var nodesBounds = (0, reactflow_1.getNodesBounds)(getNodes());
        width_1 = nodesBounds.width;
        height_1 = nodesBounds.height;
        var transform = (0, reactflow_1.getTransformForBounds)(nodesBounds, width_1, height_1, 0.5, 2);
        var edgesContainers_1 = currTarget.querySelectorAll(".react-flow__edgelabel-renderer");
        var backgroundMap_1 = {};
        edgesContainers_1.forEach(function (ec) {
            ec.childNodes.forEach(function (e) {
                var id = e.getAttribute("data-id");
                backgroundMap_1[id] = e.style.background;
                e.style.background = "#fff";
            });
        });
        (0, html_to_image_1.toJpeg)(currTarget, {
            backgroundColor: "#fff",
            width: width_1,
            height: height_1,
            canvasWidth: width_1 * 8,
            canvasHeight: height_1 * 8,
            style: {
                width: "".concat(width_1, "px"),
                height: "".concat(height_1, "px"),
                transform: "translate(".concat(transform[0], "px, ").concat(transform[1], "px) scale(").concat(transform[2], ")"),
            },
        }).then(function (canvas) {
            var format = [width_1, height_1];
            download(canvas, type, label, format, stopLoading);
            edgesContainers_1.forEach(function (ec) {
                ec.childNodes.forEach(function (e) {
                    e.style.background = backgroundMap_1[e.getAttribute("data-id")];
                });
            });
        });
    }
    else {
        stopLoading && stopLoading();
    }
};
exports.handleExport = handleExport;
