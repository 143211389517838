"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getValues = exports.getKeys = exports.getEntries = void 0;
function getEntries(obj) {
    return Object.entries(obj);
}
exports.getEntries = getEntries;
function getKeys(obj) {
    return Object.keys(obj);
}
exports.getKeys = getKeys;
function getValues(obj) {
    return Object.values(obj);
}
exports.getValues = getValues;
