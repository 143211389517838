"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var tss_react_1 = require("tss-react");
var MQLarge = "@media (max-width: 1350px)";
var ellipsis = (0, tss_react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nto {\n  width: 14px;\n}"], ["\nto {\n  width: 14px;\n}"])));
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a, _b, _c;
    return ({
        horizontalNodeOffTimeLine: {
            width: "100%",
        },
        colorPicker: {
            right: 10,
            bottom: 100,
        },
        editBar: {
            position: "absolute",
            width: 50,
            display: "flex",
            justifyContent: "flex-end",
            cursor: "auto",
            height: 25,
            right: "5px",
            top: "-30px",
        },
        closeButton: {
            "&:hover ": {
                backgroundColor: "transparent",
            },
            "&:hover *": {
                backgroundColor: "transparent",
                color: theme.palette.error.main,
            },
        },
        nodeChildAdd: {
            zIndex: -1,
            width: 180,
            height: 40,
            left: 120,
            display: "flex",
            justifyContent: "center",
            cursor: "auto",
            position: "absolute",
        },
        nodeChildAddEndNode: {
            zIndex: -1,
            width: 60,
            height: 40,
            left: 120,
            // border: "1px solid blue",
            cursor: "auto",
            position: "absolute",
        },
        itemButton: {
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: 8,
        },
        attachmentContainer: {
            marginTop: 10,
            marginBottom: 10,
        },
        verticalCollapseExpandContainer: {
            position: "absolute",
            height: 15,
            width: 20,
            bottom: 63,
            left: 127,
            cursor: "auto",
        },
        countContainer: {
            padding: 2,
            paddingRight: 8,
            paddingLeft: 8,
            backgroundColor: theme.palette.background.default,
        },
        tagCount: {
            color: theme.palette.info.main,
        },
        popover: {
            position: "fixed",
            bottom: 30,
            right: 10,
            zIndex: 1051,
        },
        cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        },
        row: {
            display: "flex",
        },
        fat: {
            fontWeight: "bold",
            minWidth: "150px",
        },
        attributSaveButtonContainer: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 5,
            marginBottom: 5,
        },
        horizontalNodeOnTimeLine: {
            display: "flex",
            padding: 12,
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            alignSelf: "center",
            cursor: "auto",
            backgroundColor: "white",
        },
        flex: {
            display: "flex",
        },
        type: {
            fontSize: 14,
            fontWeight: "bold",
            marginRight: 10,
            whiteSpace: "nowrap",
        },
        content: {
            fontSize: 14,
        },
        appBar: {
            position: "relative",
        },
        tableTitle: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        emailTitle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: 18,
        },
        horizontalHandle: {
            border: 0,
            width: 1,
            height: 1,
            visibility: "hidden",
        },
        horizontalDate: {
            fontSize: 12,
            fontWeight: 600,
        },
        personDiv: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
        },
        peopleAndDocumentsContainer: {
            padding: 10,
        },
        personAndDocsDivider: {
            marginTop: 10,
            marginBottom: 10,
        },
        verticalTitleNode: {
            fontSize: 10,
            fontWeight: "bold",
            color: "#000",
            textAlign: "center",
        },
        createElementContainer: {
            padding: 16,
            width: "100%",
            overflowY: "auto",
        },
        eventField: {
            width: "100%",
        },
        eventSelectField: {
            width: "100%",
            marginTop: 8,
            marginBottom: 8,
            position: "relative",
        },
        field: {
            width: "100%",
            marginBottom: 10,
        },
        textEditor: {
            background: theme.palette.background.paper,
            minHeight: 200,
            border: "1px solid ".concat(theme.palette.divider),
            padding: "0 10px",
            color: theme.palette.text.primary,
            borderRadius: theme.rounded.small,
        },
        toolbarEditor: {
            borderRadius: theme.rounded.small,
            background: theme.palette.background.default,
            border: "none",
            "& > div": {
                background: theme.palette.background.paper,
                "& img": {
                    filter: "invert(0%)",
                },
                "& a": {
                    color: theme.palette.text.primary,
                    "& > div": {
                        borderTopColor: theme.palette.text.primary,
                    },
                },
            },
        },
        emailContent: {
            "* > hover": {
                backgroundColor: "pink",
            },
        },
        mailFab: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        dropzone: {
            width: "100%",
            height: "100%",
        },
        contentContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
        },
        contentInnerContainer: {
            width: "100%",
            height: "100%",
            padding: 20,
        },
        noContent: {
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
        },
        contentDivider: {
            margin: 10,
        },
        buttonGroup: {
            position: "fixed",
            bottom: 10,
            right: 0,
            marginTop: 10,
            padding: 10,
        },
        verticalDate: {
            fontSize: 10,
            color: "white",
        },
        verticalTitle: {
            fontSize: 22,
            fontWeight: "bold",
        },
        verticalDescription: {
            marginTop: 10,
        },
        verticalContent: {},
        dropzone2: {
            display: "flex",
            width: "100%",
            backgroundColor: "#F7F8FA",
            borderRadius: theme.rounded.small,
            border: "1px solid #F1F1F1",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 1000,
        },
        addCircle: {
            width: "30%",
            height: "30%",
        },
        thumbsContainer: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        thumb: {
            display: "inline-flex",
            flexDirection: "column",
            border: "1px solid #eaeaea",
            marginBottom: 8,
            marginRight: 8,
            width: 100,
            height: 100,
            padding: 4,
            borderRadius: theme.rounded.small,
            boxSizing: "border-box",
        },
        thumbInner: {
            width: "100%",
            height: "100%",
            minWidth: 0,
            overflow: "hidden",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        },
        nonSvgFill: {
            "& .rpv-core__viewer": {
                "& svg": {
                    fill: "none !important",
                },
            },
        },
        img: {
            display: "block",
            width: "auto",
            height: "100%",
        },
        toggleableDuplicateDiv: {
            borderRadius: "12px",
            padding: "15px",
            height: "fit-content",
            border: "3px solid #dedede",
            cursor: "pointer",
            "& a": {
                pointerEvents: "none",
            },
        },
        duplicateToggled: {
            borderColor: theme.palette.info.main,
        },
        removeGlobalStyles: {
            "& ul, ol": {
                listStyleType: "revert",
                margin: "revert",
                padding: "revert",
            },
            "& dl": {
                margin: "revert",
            },
            "& dt": {
                fontWeight: "revert",
                margin: "revert",
            },
            "& dd": {
                margin: "revert",
            },
            "& figure": {
                margin: "revert",
            },
            "& img, picture": {
                margin: "revert",
                maxWidth: "revert",
            },
            "& table": {
                borderCollapse: "revert",
                margin: "revert",
                textAlign: "revert",
                width: "revert",
            },
            "& thead": {
                lineHeight: "revert",
                verticalAlign: "revert",
            },
            "& tbody": {
                verticalAlign: "revert",
            },
            "& tr": {
                borderBottom: "revert",
            },
            "& th": {
                fontWeight: "revert",
            },
            "& th, td": {
                padding: "revert",
                paddingLeft: "revert",
            },
            "&  h1,  h2,  h3,  h4,  h5,  h6": {
                fontFamily: "revert",
                fontSize: "revert",
                lineHeight: "revert",
                margin: "revert",
            },
            "& a": {
                color: "revert",
                textDecorationSkipInk: "revert",
                transition: "revert",
                "&:focus": {
                    outline: "revert",
                    outlineOffset: "revert",
                },
            },
            "& hr": {
                borderBottom: "revert",
                borderLeft: "revert",
                borderRight: "revert",
                borderTop: "revert",
                margin: "revert",
            },
            "& blockquote": {
                marginBlockStart: "0px",
                marginBlockEnd: "0px",
                marginInlineStart: "10px",
                marginInlineEnd: "0px",
            },
        },
        preventTableOverflow: {
            "& table": {
                maxWidth: "566px !important",
            },
        },
        linkIconButton: {
            // position: "absolute",
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            },
        },
        attachIconButton: {
            backgroundColor: "#F3F5F8",
            "&:hover": {
                backgroundColor: "#EEEFF0",
            },
        },
        horizontalLinkHover: {
            "&:hover": {
                height: "60px !important",
            },
        },
        verticalLinkHover: {
            "&:hover": {
                width: "60px !important",
            },
        },
        // Workaround so hover is not state based
        styleChildsOnHover: {
            "&:hover": {
                "& .hideOnParentHover": {
                    display: "none",
                },
                "& .revealOnParentHover": {
                    display: "block !important",
                },
                "& .changeBackgroundOnParentHover": {
                    backgroundColor: "#F3F5F8",
                    "&:hover": {
                        backgroundColor: "#EEEFF0",
                    },
                },
            },
        },
        horizontalMoveNodeButton: {
            position: "absolute",
            top: 0,
            alignItems: "flex-end",
            display: "flex",
            borderRadius: 17,
            zIndex: "-1",
        },
        horizontalRevealMoveButtonOnHover: {
            "&:hover": {
                "& .horizontalMoveNodeButtonAnimation": {
                    marginTop: "38px",
                },
            },
        },
        verticalMoveNodeButton: {
            position: "absolute",
            left: 0,
            justifyContent: "flex-end",
            display: "flex",
            borderRadius: 17,
            zIndex: "-1",
        },
        verticalRevealMoveButtonOnHover: {
            "&:hover": {
                "& .verticalMoveNodeButtonAnimation": {
                    marginLeft: "38px",
                },
            },
        },
        styleViewer: {
            "& .rpv-core__page-layer": {
                overflow: "visible",
            },
            flex: 1,
            overflow: "hidden",
        },
        viewerZoomButtons: {
            alignItems: "center",
            backgroundColor: "#eeeeee",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "center",
            flex: 1,
            padding: "4px",
            maxHeight: "37px",
            // "& svg": {
            //   fill: "none !important",
            // },
        },
        viewerThumbnails: {
            borderRight: "1px solid rgba(0, 0, 0, 0.1)",
            width: 180,
        },
        viewerThumbnailAndPage: {
            border: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            width: "100%",
            overflow: "hidden",
        },
        viewerCoreContainer: {
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
        },
        viewerOuterContainer: (_a = {
                display: "flex",
                justifyContent: "space-evenly",
                gap: "20px",
                padding: "1rem",
                marginBottom: "16px",
                paddingBottom: "0",
                overflow: "hidden",
                flex: 1
            },
            _a[MQLarge] = {
            // width: "90%",
            },
            _a),
        dottedPdfSplitLine: {
            position: "absolute",
            bottom: "-46px",
            width: "95%",
            border: "dashed 2px",
        },
        splitContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            overflow: "hidden",
            transition: "gap 0.3s ease",
        },
        documentFormContainer: {
            overflowY: "auto",
            overflowX: "auto",
            width: "400px",
            padding: "8px",
            flex: 1,
        },
        sameDateHorizontalNodeOffTimeLine: {
            width: 250,
            bottom: 80,
            position: "absolute",
            cursor: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
        },
        relativeFullWidth: {
            width: "100%",
            position: "relative",
        },
        flexEndSpaceBetween: {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
        },
        flexEndCenter: {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
        },
        editIconButton: {
            width: 22.5,
            height: 22,
            opacity: 0.5,
            ":hover": {
                opacity: 1,
            },
        },
        editIcon: {
            fontSize: 10,
            position: "absolute",
        },
        toggleChildrenIconButton: {
            width: 22,
            height: 22,
            opacity: 0.5,
            ":hover": {
                opacity: 1,
            },
        },
        toggleChildrenButton: {
            fontSize: 22,
            position: "absolute",
        },
        flexCentered: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        timelinePeopleOuterContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        flexAlignedCenter: {
            display: "flex",
            alignItems: "center",
        },
        personIconContainer: {
            cursor: "pointer",
            margin: "0 2px",
        },
        personIcon: {
            width: 20,
            height: 20,
            fontSize: 8,
        },
        periodHeader: {
            margin: 1,
            marginBottom: 0,
            fontSize: "10px",
        },
        eventHeader: {
            fontWeight: "bold",
            marginLeft: 1,
            marginRight: 1,
            fontSize: "14px",
        },
        flexAlignedCenterJustifiedCenter: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        eventBody: {
            display: "flex",
            alignItems: "center",
            marginTop: 6,
        },
        eventTagsContainer: {
            position: "absolute",
            bottom: 5,
            right: 5,
            display: "flex",
            alignItems: "center",
        },
        eventTag: {
            width: 12,
            height: 12,
            borderRadius: 6,
            margin: 1,
        },
        buttons: {
            "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.primary.main,
            },
            fontSize: 24,
        },
        reactPdfButtonContainer: {
            display: "flex",
            justifyContent: "space-between",
            margin: "0 22px 12px 12px",
            border: "none",
            boxShadow: theme.shadows[1],
            borderRadius: 8,
            flex: 3,
        },
        reactPdfThumnnail: {
            width: 180,
        },
        reactPdfViewContainer: (_b = {
                display: "flex",
                flexDirection: "column",
                height: "100%",
                minWidth: 900,
                width: "100%",
                flex: 2
            },
            _b[MQLarge] = {
                minWidth: 0,
            },
            _b),
        reactPdfViewer: {
            display: "flex",
            width: "100%",
            height: "100%",
            overflow: "hidden",
        },
        reactPdfScrollbar: {
            "> div > div": {
                scrollbarWidth: "thin",
                scrollbarColor: "".concat(theme.palette.primary.main, " ").concat(theme.palette.primary.light),
                "&::-webkit-scrollbar": {
                    width: 8,
                },
                "&::-webkit-scrollbar-track": {
                    background: theme.palette.primary.light,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 10,
                },
            },
            "> div > div > div": {
                scrollbarWidth: "thin",
                scrollbarColor: "".concat(theme.palette.primary.main, " ").concat(theme.palette.primary.light),
                "&::-webkit-scrollbar": {
                    width: 8,
                },
                "&::-webkit-scrollbar-track": {
                    background: theme.palette.primary.light,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 10,
                },
            },
            scrollbarWidth: "thin",
            scrollbarColor: "".concat(theme.palette.primary.main, " ").concat(theme.palette.primary.light),
            "&::-webkit-scrollbar": {
                width: 8,
            },
            "&::-webkit-scrollbar-track": {
                background: theme.palette.primary.light,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 10,
            },
        },
        iconButton: {
            ":hover": {
                "> svg": {
                    color: theme.palette.primary.main,
                },
            },
            "&--selected": {
                "> svg": {
                    color: theme.palette.primary.main,
                },
            },
        },
        navigateHighlightButton: {
            transform: "rotate(90deg)",
        },
        highlightItem: {
            padding: 0,
            "&&": {
                backgroundColor: "#ffffcc80",
            },
            "&&.rpv-search__highlight--current": {
                background: theme.palette.primary.main + "60",
            },
            "&&.rpv-search__highlight__custom--current": {
                background: theme.palette.primary.dark + "40",
            },
            "&.rpv-search__highlight__custom": {
                background: "#ffcc8080",
            },
            "&.rpv-search__highlight--missing-action": {
                background: theme.palette.error.main + 60,
            },
        },
        reactPdfToolTipContainer: {
            transform: "translate(0, 8px)",
            zIndex: 1,
            display: "flex",
            background: theme.palette.background.default,
            boxShadow: "#aaa 2px 2px 4px",
            borderRadius: 8,
        },
        createNodeContainer: {
            flex: 1,
            // background: theme.palette.primary.light + 40,
            minWidth: 500,
            overflow: "auto",
            padding: "16px",
        },
        highlightListItem: {
            overflowWrap: "break-word",
            wordWrap: "break-word",
            wordBreak: "break-word",
            width: 180,
            "&--selected": {
                color: theme.palette.primary.main,
            },
        },
        noHighlights: {
            margin: "auto 1rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: 5,
            height: "100%",
        },
        warningIcon: {
            color: theme.palette.warning.main,
            alignSelf: "center",
        },
        floatingPanel: {
            "&&&": (_c = {
                    width: "auto",
                    display: "flex",
                    flexDirection: "column"
                },
                _c[MQLarge] = {
                    width: "90%",
                },
                _c),
        },
        menuContainer: {
            width: 150,
            position: "absolute",
            zIndex: 9999,
        },
        deleteButton: {
            gap: 5,
            "& > *": {
                color: theme.palette.error.main,
                "& > svg": {
                    color: theme.palette.error.main,
                },
            },
        },
        noHighlightLottie: {
            width: 150,
            height: 150,
            transform: "translate(-65px, -40px)",
        },
        noHighlightInfo: {
            transform: "translate(0px, -50px)",
        },
        loadingLottie: {
            width: 150,
            height: 150,
        },
        notFoundLottie: {
            width: "50%",
            height: "50%",
        },
        overwritePadding10: {
            "&&&": {
                padding: 10,
            },
        },
        importSettingsButton: {
            all: "unset",
            display: "flex",
            gap: 5,
            "&:hover": {
                cursor: "pointer",
                "&:disabled": {
                    background: "none",
                },
            },
            "&__subitem": {
                paddingLeft: 16,
            },
        },
        textLoading: {
            "&:after": {
                overflow: "hidden",
                display: "inline-block",
                verticalAlign: "bottom",
                animation: "".concat(ellipsis, " steps(4, end) 3s infinite"),
                content: "'\\2026'",
                width: "0px",
            },
        },
    });
});
exports.default = useStyles;
var templateObject_1;
