"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function fileDropzoneCallback(handleFileChange) {
    return {
        onDrop: function (acceptedFiles) {
            handleFileChange &&
                handleFileChange(acceptedFiles.map(function (_file) {
                    return Object.assign(_file, {
                        preview: URL.createObjectURL(_file),
                    });
                }));
        },
        accept: {
            "application/msword": [".doc"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                ".docx",
            ],
            "application/vnd.ms-powerpoint": [".ppt"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
                ".pptx",
            ],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/pdf": [".pdf"],
            "application/vnd.oasis.opendocument.text": [".odt"],
            "application/octet-stream": [".nrl", ".eml"],
        },
    };
}
exports.default = fileDropzoneCallback;
