"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHelperLines = void 0;
// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
function getHelperLines(change, nodes, distance) {
    var _a, _b, _c, _d;
    if (distance === void 0) { distance = 5; }
    var defaultResult = {
        horizontal: undefined,
        vertical: undefined,
        snapPosition: { x: undefined, y: undefined },
    };
    var nodeA = nodes.find(function (node) { return node.id === change.id; });
    if (!nodeA || !change.position || nodeA.parentNode) {
        return defaultResult;
    }
    var nodeABounds = {
        left: change.position.x,
        right: change.position.x + ((_a = nodeA.width) !== null && _a !== void 0 ? _a : 0),
        top: change.position.y,
        bottom: change.position.y + ((_b = nodeA.height) !== null && _b !== void 0 ? _b : 0),
        width: (_c = nodeA.width) !== null && _c !== void 0 ? _c : 0,
        height: (_d = nodeA.height) !== null && _d !== void 0 ? _d : 0,
    };
    var horizontalDistance = distance;
    var verticalDistance = distance;
    return nodes
        .filter(function (node) { return node.id !== nodeA.id; })
        .reduce(function (result, nodeB) {
        var _a, _b, _c, _d;
        var nodeBBounds = {
            left: nodeB.position.x,
            right: nodeB.position.x + ((_a = nodeB.width) !== null && _a !== void 0 ? _a : 0),
            top: nodeB.position.y,
            bottom: nodeB.position.y + ((_b = nodeB.height) !== null && _b !== void 0 ? _b : 0),
            width: (_c = nodeB.width) !== null && _c !== void 0 ? _c : 0,
            height: (_d = nodeB.height) !== null && _d !== void 0 ? _d : 0,
        };
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //  |
        //  |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);
        if (distanceLeftLeft < verticalDistance) {
            result.snapPosition.x = nodeBBounds.left;
            result.vertical = nodeBBounds.left;
            verticalDistance = distanceLeftLeft;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //              |
        //              |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);
        if (distanceRightRight < verticalDistance) {
            result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
            result.vertical = nodeBBounds.right;
            verticalDistance = distanceRightRight;
        }
        //              |‾‾‾‾‾‾‾‾‾‾‾|
        //              |     A     |
        //              |___________|
        //              |
        //              |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);
        if (distanceLeftRight < verticalDistance) {
            result.snapPosition.x = nodeBBounds.right;
            result.vertical = nodeBBounds.right;
            verticalDistance = distanceLeftRight;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //              |
        //              |
        //              |‾‾‾‾‾‾‾‾‾‾‾|
        //              |     B     |
        //              |___________|
        var distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);
        if (distanceRightLeft < verticalDistance) {
            result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
            result.vertical = nodeBBounds.left;
            verticalDistance = distanceRightLeft;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |     |     B     |
        //  |___________|     |___________|
        var distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);
        if (distanceTopTop < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.top;
            result.horizontal = nodeBBounds.top;
            horizontalDistance = distanceTopTop;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|_________________
        //                    |           |
        //                    |     B     |
        //                    |___________|
        var distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);
        if (distanceBottomTop < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
            result.horizontal = nodeBBounds.top;
            horizontalDistance = distanceBottomTop;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |     |     B     |
        //  |___________|_____|___________|
        var distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);
        if (distanceBottomBottom < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
            result.horizontal = nodeBBounds.bottom;
            horizontalDistance = distanceBottomBottom;
        }
        //                    |‾‾‾‾‾‾‾‾‾‾‾|
        //                    |     B     |
        //                    |           |
        //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
        //  |     A     |
        //  |___________|
        var distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);
        if (distanceTopBottom < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.bottom;
            result.horizontal = nodeBBounds.bottom;
            horizontalDistance = distanceTopBottom;
        }
        return result;
    }, defaultResult);
}
exports.getHelperLines = getHelperLines;
