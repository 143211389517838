"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BUNDLE_SECTIONS_DROPDOWN_SUCCESS = exports.GET_BUNDLE_SECTIONS_DROPDOWN_LOADING = exports.DELETE_BUNDLE_SECTION_FAILED = exports.DELETE_BUNDLE_SECTION_SUCCESS = exports.DELETE_BUNDLE_SECTION_LOADING = exports.ADD_BUNDLE_SECTION_FAILED = exports.ADD_BUNDLE_SECTION_SUCCESS = exports.ADD_BUNDLE_SECTION_LOADING = exports.CHANGE_BUNDLE_SECTION_ORDER_FAILED = exports.CHANGE_BUNDLE_SECTION_ORDER_SUCCESS = exports.CHANGE_BUNDLE_SECTION_ORDER_LOADING = exports.CHANGE_BUNDLE_SECTION_TITLE_FAILED = exports.CHANGE_BUNDLE_SECTION_TITLE_SUCCESS = exports.CHANGE_BUNDLE_SECTION_TITLE_LOADING = exports.GET_BUNDLE_SECTIONS_FAILED = exports.GET_BUNDLE_SECTIONS_SUCCESS = exports.GET_BUNDLE_SECTIONS_LOADING = exports.DOCUMENT_DUPLICATE_FAILED = exports.DOCUMENT_DUPLICATE_SUCCESS = exports.DOCUMENT_DUPLICATE_LOADING = exports.GET_DOCUMENT_STREAM_FAILED = exports.GET_DOCUMENT_STREAM_SUCCESS = exports.GET_DOCUMENT_STREAM_LOADING = exports.DELETE_DOCUMENT_NOTE_FAILED = exports.DELETE_DOCUMENT_NOTE_SUCCESS = exports.POST_DOCUMENT_NOTE_FAILED = exports.POST_DOCUMENT_NOTE_SUCCESS = exports.GET_DOCUMENT_DESCENDANTS_FAILED = exports.GET_DOCUMENT_DESCENDANTS_SUCCESS = exports.CLEAR_DOCUMENT_DESCENDANTS = exports.CHANGE_DOCUMENT = exports.GET_DOCUMENT_DROPDOWN_FAILED = exports.GET_DOCUMENT_DROPDOWN_SUCCESS = exports.FORCE_DELETE_DOCUMENT_FAILED = exports.FORCE_DELETE_DOCUMENT_SUCCESS = exports.DELETE_DOCUMENT_FAILED = exports.DELETE_DOCUMENT_SUCCESS = exports.DELETE_DOCUMENT_LOADING = exports.PUT_DOCUMENT_FAILED = exports.PUT_DOCUMENT_SUCCESS = exports.PUT_DOCUMENT_LOADING = exports.GET_DOCUMENT_FAILED = exports.GET_DOCUMENT_SUCCESS = exports.GET_DOCUMENT_LOADING = exports.POST_DOCUMENT_FAILED = exports.POST_DOCUMENT_SUCCESS = exports.POST_DOCUMENT_LOADING = exports.GET_DOCUMENTS_FAILED = exports.GET_DOCUMENTS_SUCCESS = exports.GET_DOCUMENTS_LOADING = void 0;
exports.RESET_DOCUMENT_VALIDATION = exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_FAILED = exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_SUCCESS = exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_LOADING = exports.DOCUMENTS_ON_HOLD_FAILED = exports.REMOVE_DOCUMENT_ON_HOLD = exports.HANDLE_DOCUMENTS_ON_HOLD = exports.GET_DOCUMENTS_ON_HOLD = exports.CHANGE_DOCUMENT_OPTIONS = exports.DATES_FOUND_ON_DOCUMENT_LOADING = exports.DATES_FOUND_ON_DOCUMENT_FAILED = exports.DATES_FOUND_ON_DOCUMENT_SUCCESS = exports.AWAITED_DOCUMENTS_CLEARED = exports.UPLOAD_DOCUMENT_FIND_DATES_FAILED = exports.UPLOAD_DOCUMENT_FIND_DATES_SUCCESS = exports.UPLOAD_DOCUMENT_FIND_DATES_LOADING = exports.GET_BUNDLE_SECTIONS_DROPDOWN_FAILED = void 0;
exports.GET_DOCUMENTS_LOADING = "GET_DOCUMENTS_LOADING";
exports.GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
exports.GET_DOCUMENTS_FAILED = "GET_DOCUMENTS_FAILED";
exports.POST_DOCUMENT_LOADING = "POST_DOCUMENT_LOADING";
exports.POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
exports.POST_DOCUMENT_FAILED = "POST_DOCUMENT_FAILED";
exports.GET_DOCUMENT_LOADING = "GET_DOCUMENT_LOADING";
exports.GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
exports.GET_DOCUMENT_FAILED = "GET_DOCUMENT_FAILED";
exports.PUT_DOCUMENT_LOADING = "PUT_DOCUMENT_LOADING";
exports.PUT_DOCUMENT_SUCCESS = "PUT_DOCUMENT_SUCCESS";
exports.PUT_DOCUMENT_FAILED = "PUT_DOCUMENT_FAILED";
exports.DELETE_DOCUMENT_LOADING = "DELETE_DOCUMENT_LOADING";
exports.DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
exports.DELETE_DOCUMENT_FAILED = "DELETE_DOCUMENT_FAILED";
exports.FORCE_DELETE_DOCUMENT_SUCCESS = "FORCE_DELETE_DOCUMENT_SUCCESS";
exports.FORCE_DELETE_DOCUMENT_FAILED = "FORCE_DELETE_DOCUMENT_FAILED";
exports.GET_DOCUMENT_DROPDOWN_SUCCESS = "GET_DOCUMENT_DROPDOWN_SUCCESS";
exports.GET_DOCUMENT_DROPDOWN_FAILED = "GET_DOCUMENT_DROPDOWN_FAILED";
exports.CHANGE_DOCUMENT = "CHANGE_DOCUMENT";
exports.CLEAR_DOCUMENT_DESCENDANTS = "CLEAR_DOCUMENT_DESCENDANTS";
exports.GET_DOCUMENT_DESCENDANTS_SUCCESS = "GET_DOCUMENT_DESCENDANTS_SUCCESS";
exports.GET_DOCUMENT_DESCENDANTS_FAILED = "GET_DOCUMENT_DESCENDANTS_FAILED";
exports.POST_DOCUMENT_NOTE_SUCCESS = "POST_DOCUMENT_NOTE_SUCCESS";
exports.POST_DOCUMENT_NOTE_FAILED = "POST_DOCUMENT_NOTE_FAILED";
exports.DELETE_DOCUMENT_NOTE_SUCCESS = "DELETE_DOCUMENT_NOTE_SUCCESS";
exports.DELETE_DOCUMENT_NOTE_FAILED = "DELETE_DOCUMENT_NOTE_FAILED";
exports.GET_DOCUMENT_STREAM_LOADING = "GET_DOCUMENT_STREAM_LOADING";
exports.GET_DOCUMENT_STREAM_SUCCESS = "GET_DOCUMENT_STREAM_SUCCESS";
exports.GET_DOCUMENT_STREAM_FAILED = "GET_DOCUMENT_STREAM_FAILED";
exports.DOCUMENT_DUPLICATE_LOADING = "DOCUMENT_DUPLICATE_LOADING";
exports.DOCUMENT_DUPLICATE_SUCCESS = "DOCUMENT_DUPLICATE_SUCCESS";
exports.DOCUMENT_DUPLICATE_FAILED = "DOCUMENT_DUPLICATE_FAILED";
exports.GET_BUNDLE_SECTIONS_LOADING = "GET_BUNDLE_SECTIONS_LOADING";
exports.GET_BUNDLE_SECTIONS_SUCCESS = "GET_BUNDLE_SECTIONS_SUCCESS";
exports.GET_BUNDLE_SECTIONS_FAILED = "GET_BUNDLE_SECTIONS_FAILED";
exports.CHANGE_BUNDLE_SECTION_TITLE_LOADING = "CHANGE_BUNDLE_SECTION_TITLE_LOADING";
exports.CHANGE_BUNDLE_SECTION_TITLE_SUCCESS = "CHANGE_BUNDLE_SECTION_TITLE_SUCCESS";
exports.CHANGE_BUNDLE_SECTION_TITLE_FAILED = "CHANGE_BUNDLE_SECTION_TITLE_FAILED";
exports.CHANGE_BUNDLE_SECTION_ORDER_LOADING = "CHANGE_BUNDLE_SECTION_ORDER_LOADING";
exports.CHANGE_BUNDLE_SECTION_ORDER_SUCCESS = "CHANGE_BUNDLE_SECTION_ORDER_SUCCESS";
exports.CHANGE_BUNDLE_SECTION_ORDER_FAILED = "CHANGE_BUNDLE_SECTION_ORDER_FAILED";
exports.ADD_BUNDLE_SECTION_LOADING = "ADD_BUNDLE_SECTION_LOADING";
exports.ADD_BUNDLE_SECTION_SUCCESS = "ADD_BUNDLE_SECTION_SUCCESS";
exports.ADD_BUNDLE_SECTION_FAILED = "ADD_BUNDLE_SECTION_FAILED";
exports.DELETE_BUNDLE_SECTION_LOADING = "DELETE_BUNDLE_SECTION_LOADING";
exports.DELETE_BUNDLE_SECTION_SUCCESS = "DELETE_BUNDLE_SECTION_SUCCESS";
exports.DELETE_BUNDLE_SECTION_FAILED = "DELETE_BUNDLE_SECTION_FAILED";
exports.GET_BUNDLE_SECTIONS_DROPDOWN_LOADING = "GET_BUNDLE_SECTIONS_DROPDOWN_LOADING";
exports.GET_BUNDLE_SECTIONS_DROPDOWN_SUCCESS = "GET_BUNDLE_SECTIONS_DROPDOWN_SUCCESS";
exports.GET_BUNDLE_SECTIONS_DROPDOWN_FAILED = "GET_BUNDLE_SECTIONS_DROPDOWN_FAILED";
exports.UPLOAD_DOCUMENT_FIND_DATES_LOADING = "UPLOAD_DOCUMENT_FIND_DATES_LOADING";
exports.UPLOAD_DOCUMENT_FIND_DATES_SUCCESS = "UPLOAD_DOCUMENT_FIND_DATES_SUCCESS";
exports.UPLOAD_DOCUMENT_FIND_DATES_FAILED = "UPLOAD_DOCUMENT_FIND_DATES_FAILED";
exports.AWAITED_DOCUMENTS_CLEARED = "AWAITED_DOCUMENTS_CLEARED";
exports.DATES_FOUND_ON_DOCUMENT_SUCCESS = "DATES_FOUND_ON_DOCUMENT_SUCCESS";
exports.DATES_FOUND_ON_DOCUMENT_FAILED = "DATES_FOUND_ON_DOCUMENT_FAILED";
exports.DATES_FOUND_ON_DOCUMENT_LOADING = "DATES_FOUND_ON_DOCUMENT_LOADING";
exports.CHANGE_DOCUMENT_OPTIONS = "CHANGE_DOCUMENT_OPTIONS";
exports.GET_DOCUMENTS_ON_HOLD = "GET_DOCUMENTS_ON_HOLD";
exports.HANDLE_DOCUMENTS_ON_HOLD = "HANDLE_DOCUMENTS_ON_HOLD";
exports.REMOVE_DOCUMENT_ON_HOLD = "REMOVE_DOCUMENT_ON_HOLD";
exports.DOCUMENTS_ON_HOLD_FAILED = "DOCUMENTS_ON_HOLD_FAILED";
exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_LOADING = "UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_LOADING";
exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_SUCCESS = "UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_SUCCESS";
exports.UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_FAILED = "UPLOAD_DOCUMENTS_TO_JURIST_IQ_GROUP_FAILED";
exports.RESET_DOCUMENT_VALIDATION = "RESET_DOCUMENT_VALIDATION";
