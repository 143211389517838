"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var expand = {
    bottom: "auto",
    right: "auto",
    left: "50%",
    top: "50%",
    transform: "translateX(-50%) translateY(-50%)",
};
var styles = (0, mui_1.makeStyles)()(function (theme, _params, classes) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        formTheme: {
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[7],
        },
        hideForm: {
            visibility: "hidden",
            opacity: 0,
            bottom: -20,
            transform: "scale(0.9)",
        },
        btnOpt: {},
        expandButton: (_a = {},
            _a[theme.breakpoints.down("lg")] = {
                display: "none",
            },
            _a),
        closeButton: {},
        floatingForm: (_b = {
                transition: "all 0.3s cubic-bezier(0.01, 0.65, 0.3, 0.9)",
                position: "fixed",
                width: 500,
                right: 10,
                zIndex: 0,
                border: "1px solid ".concat(theme.palette.primary.main),
                borderRadius: theme.rounded.medium,
                overflow: "hidden"
            },
            _b[theme.breakpoints.down("md")] = __assign({ width: "95% !important" }, expand),
            _b["& header"] = (_c = {
                    color: theme.palette.mode === "dark"
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark,
                    fontSize: 22,
                    padding: "24px 20px",
                    paddingBottom: 0,
                    position: "relative",
                    boxShadow: "0 10px 10px 10px ".concat(theme.palette.background.paper),
                    fontWeight: 600,
                    marginBottom: theme.spacing(1),
                    zIndex: 1,
                    "&:after": {
                        content: "''",
                        position: "absolute",
                        width: 60,
                        height: 6,
                        background: theme.palette.primary.main,
                        bottom: -8,
                        left: 20,
                    }
                },
                _c["& .".concat(classes.btnOpt)] = (_d = {
                        position: "absolute",
                        right: 0,
                        top: 0,
                        "& > *": {
                            margin: "0 5px",
                        }
                    },
                    _d["& .".concat(classes.expandButton)] = {
                        transform: "rotate(270deg)",
                    },
                    _d["& svg"] = {
                        fill: theme.palette.text.secondary,
                    },
                    _d),
                _c),
            _b["& [class^='bodyForm']"] = {
                backgroundColor: theme.palette.background.paper,
            },
            _b["& [class^='buttonArea']"] = {
                backgroundColor: theme.palette.background.default,
            },
            _b),
        showForm: (_e = {
                visibility: "visible",
                opacity: 1,
                zIndex: 1300
            },
            _e[theme.breakpoints.up("md")] = {
                bottom: 10,
                transform: "scale(1)",
            },
            _e),
        buttonArea: {
            background: theme.palette.primary.light,
            position: "relative",
            bottom: 0,
            left: 0,
            width: "100%",
            textAlign: "right",
            padding: "10px 30px",
            "& button": {
                marginRight: 5,
            },
        },
        expanded: __assign({}, expand),
        formOverlay: {
            position: "fixed",
            background: theme.palette.grey[900],
            opacity: 0.7,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1300,
        },
        large: {
            width: 650,
        },
        bodyForm: (_f = {
                position: "relative",
                background: theme.palette.background.paper,
                padding: theme.spacing(2)
            },
            _f[theme.breakpoints.down("lg")] = {
                padding: "15px 10px",
            },
            _f.maxHeight = 450,
            _f.overflow = "auto",
            _f),
        removePseudo: {
            "&:after": {
                position: "relative !important",
            },
        },
        noMargin: {
            margin: "0 !important",
        },
    });
});
exports.default = styles;
