"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
function useElementsSelected(dispatch, editable) {
    var _a = (0, react_1.useState)([]), selectedNodes = _a[0], setSelectedNodes = _a[1];
    var _b = (0, react_1.useState)([]), selectedEdges = _b[0], setSelectedEdges = _b[1];
    var storeApi = (0, reactflow_1.useStoreApi)();
    (0, reactflow_1.useOnSelectionChange)({
        onChange: editable
            ? function onChange(_a) {
                var nodes = _a.nodes, edges = _a.edges;
                if (nodes.length > 0 || selectedNodes.length > 0) {
                    var nodesSelected = nodes.map(function (node) { return node.id; });
                    if (nodesSelected.length > 1) {
                        setTimeout(function () {
                            storeApi.setState(function () { return ({ nodesSelectionActive: true }); }); // show selection box
                        }, 1);
                    }
                    setSelectedNodes(nodesSelected);
                    dispatch((0, workspaceActions_websocket_1.userEditNodes)(nodesSelected));
                }
                if (edges.length > 0) {
                    var edgesSelected = edges
                        .map(function (edge) { var _a; return (((_a = edge.data) === null || _a === void 0 ? void 0 : _a.editing) ? null : edge.id); })
                        .filter(function (edge) { return edge !== null; });
                    setSelectedEdges(edgesSelected);
                    dispatch((0, workspaceActions_websocket_1.userEditEdges)(edgesSelected));
                }
            }
            : function () { },
    });
    var handleNodeDeselect = (0, react_1.useCallback)(function (nodeId) {
        if (nodeId) {
            if (!selectedNodes.includes(nodeId)) {
                selectedNodes.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingNodes)(selectedNodes));
                setSelectedNodes([]);
            }
        }
        else {
            selectedNodes.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingNodes)(selectedNodes));
            setSelectedNodes([]);
        }
    }, [selectedNodes]);
    var handleEdgeDeselect = (0, react_1.useCallback)(function (deselectReduxState) {
        if (deselectReduxState === void 0) { deselectReduxState = false; }
        selectedEdges.length > 0 && dispatch((0, workspaceActions_websocket_1.userFinishEditingEdges)(selectedEdges));
        if (deselectReduxState) {
            (0, workspaceActions_http_1.deselectEdges)(dispatch);
        }
        setSelectedEdges([]);
    }, [selectedEdges]);
    return {
        selectedNodes: selectedNodes,
        handleNodeDeselect: handleNodeDeselect,
        handleEdgeDeselect: handleEdgeDeselect,
    };
}
exports.default = useElementsSelected;
