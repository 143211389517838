"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createClient = void 0;
/* eslint-disable no-undef-init */
var socket_io_client_1 = require("socket.io-client");
function createClient(socket) {
    return function io(url, auth) {
        if (!socket) {
            socket = (0, socket_io_client_1.io)(url, { auth: auth, path: "/websocket", transports: ["websocket"] });
        }
        if (!socket.connected) {
            socket.connect();
        }
        return socket;
    };
}
exports.createClient = createClient;
