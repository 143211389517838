"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useDimFlowItems() {
    var dimFlowItem = (0, react_1.useCallback)(function (isNode, _id) {
        document.querySelectorAll(".react-flow__node").forEach(function (node) {
            // Using the child because react flow overrides the classes on the node on click
            var nodeChild = node.children[0];
            if (_id === null) {
                nodeChild.classList.remove("dim");
            }
            else if (isNode === false) {
                nodeChild.classList.add("dim");
            }
            else if (isNode === true) {
                var nodeId = node.getAttribute("data-id");
                if (nodeId !== _id) {
                    nodeChild.classList.add("dim");
                }
                else {
                    nodeChild.classList.remove("dim");
                }
            }
        });
        document.querySelectorAll(".react-flow__edge").forEach(function (edge) {
            var _a;
            if (_id === null) {
                edge.classList.remove("dim");
            }
            else if (isNode === true) {
                edge.classList.add("dim");
            }
            else if (isNode === false) {
                var edgeId = (_a = edge.getAttribute("data-testid")) === null || _a === void 0 ? void 0 : _a.replace("rf__edge-", "");
                if (edgeId !== _id) {
                    edge.classList.add("dim");
                }
                else {
                    edge.classList.remove("dim");
                }
            }
        });
    }, []);
    var dimFlowItems = (0, react_1.useCallback)(function (nodeIds, edgeIds) {
        document.querySelectorAll(".react-flow__node").forEach(function (node) {
            var nodeId = node.getAttribute("data-id");
            if (!nodeIds) {
                node.classList.remove("dim");
            }
            else if (nodeId && nodeIds.includes(nodeId)) {
                node.classList.remove("dim");
            }
            else {
                node.classList.add("dim");
            }
        });
        document.querySelectorAll(".react-flow__edge").forEach(function (edge) {
            var _a;
            var edgeId = (_a = edge.getAttribute("data-testid")) === null || _a === void 0 ? void 0 : _a.replace("rf__edge-", "");
            if (!edgeIds) {
                edge.classList.remove("dim");
            }
            else if (edgeId && edgeIds.includes(edgeId)) {
                edge.classList.remove("dim");
            }
            else {
                edge.classList.add("dim");
            }
        });
    }, []);
    return { dimFlowItem: dimFlowItem, dimFlowItems: dimFlowItems };
}
exports.default = useDimFlowItems;
