"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    dragger: {
        width: "14px",
        cursor: "ew-resize",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: "100",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparant",
        transition: "background-color 0.3s ease",
        "& .MuiSvgIcon-root": {
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.3s ease, visibility 0.3s ease",
        },
        "&:hover": {
            backgroundColor: "#f4f7f9",
            "& .MuiSvgIcon-root": {
                opacity: 1,
                visibility: "visible",
            },
        },
    },
    panel: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    header: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    card: {
        borderStyle: "solid",
    },
    cardContent: {
        height: 200,
        display: "flex",
        flexDirection: "column",
        "&&&&": { padding: theme.spacing(0) },
    },
    cardContentPaper: {
        padding: theme.spacing(0.5),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 0,
        borderBottom: "0.2px solid rgba(0,0,0,0.5)",
    },
    cardActionArea: {
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        position: "relative",
    },
    cardActionOverlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
        color: "white",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    cardActionOverlayActive: {
        "&&&": { opacity: 1 },
    },
    scrollArea: __assign({ height: "100%", width: "100%", overflow: "auto", padding: theme.spacing(2) }, (0, styles_1.JuristicScroll)(theme)),
    renameInput: {
        flexGrow: 1,
        marginLeft: 1,
        margin: 0,
        "& .MuiInputBase-input": {
            padding: 0,
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            fontSize: "0.875rem",
        },
    },
    stepAdder: {
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    stepAdderContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(5),
        gap: theme.spacing(1),
    },
}); });
