"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var d3_timer_1 = require("d3-timer");
var redux_1 = require("@hooks/redux");
var defaultOptions = { duration: 500 };
function useAnimatedNodes(_a) {
    var _b = _a.options, options = _b === void 0 ? defaultOptions : _b, _c = _a.isDragging, isDragging = _c === void 0 ? false : _c;
    var initialNodes = (0, redux_1.useAppSelector)(function (state) { return state.timeline.nodes; });
    var _d = (0, react_1.useState)(initialNodes), nodes = _d[0], setNodes = _d[1];
    var _e = (0, react_1.useState)(false), shouldDisableMouseEnter = _e[0], setDisableMouseEnter = _e[1];
    var getNode = (0, reactflow_1.useReactFlow)().getNode;
    var nodesToAnimate = (0, react_1.useMemo)(function () { return initialNodes.filter(function (n) { return n.type !== "stickyNote"; }); }, [initialNodes]);
    var stickyNotes = (0, react_1.useMemo)(function () { return initialNodes.filter(function (n) { return n.type === "stickyNote"; }); }, [initialNodes]);
    (0, react_1.useEffect)(function () {
        if (isDragging) {
            setNodes(initialNodes);
            return function () { };
        }
        setDisableMouseEnter(true);
        var transitions = nodesToAnimate.map(function (node) {
            var _a, _b;
            return ({
                id: node.id,
                from: (_b = (_a = getNode(node.id)) === null || _a === void 0 ? void 0 : _a.position) !== null && _b !== void 0 ? _b : node.position,
                to: node.position,
                node: node,
            });
        });
        var t = (0, d3_timer_1.timer)(function (elapsed) {
            var s = elapsed / options.duration;
            if (elapsed >= options.duration)
                s = 1;
            var currNodes = transitions.map(function (_a) {
                var node = _a.node, from = _a.from, to = _a.to;
                return (__assign(__assign({}, node), { position: {
                        x: from.x + (to.x - from.x) * s,
                        y: from.y + (to.y - from.y) * s,
                    } }));
            });
            setNodes(__spreadArray(__spreadArray([], currNodes, true), stickyNotes, true));
            if (elapsed >= options.duration) {
                t.stop();
                setDisableMouseEnter(false);
            }
        });
        return function () { return t.stop(); };
    }, [initialNodes, getNode]);
    return [nodes, shouldDisableMouseEnter];
}
exports.default = useAnimatedNodes;
