"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsEdgeSelectionChangeable = exports.setIsEdgeSelectionChangeable = exports.useBaseEdge = void 0;
var react_1 = require("react");
var react_singleton_hook_1 = require("react-singleton-hook");
var isKeepEdgeSelectionChangeableInit = true;
var isEdgeSelectionChangeable = isKeepEdgeSelectionChangeableInit;
var globalIsEdgeSelectionChangeable = function () {
    throw new Error("You must useBaseEdge before setting its state");
};
exports.useBaseEdge = (0, react_singleton_hook_1.singletonHook)(isKeepEdgeSelectionChangeableInit, function () {
    var _a = (0, react_1.useState)(isKeepEdgeSelectionChangeableInit), _isEdgeSelectionChangeable = _a[0], _setIsEdgeSelectionChangeable = _a[1];
    globalIsEdgeSelectionChangeable = _setIsEdgeSelectionChangeable;
    isEdgeSelectionChangeable = _isEdgeSelectionChangeable;
    return _isEdgeSelectionChangeable;
});
var setIsEdgeSelectionChangeable = function (bool) {
    return globalIsEdgeSelectionChangeable(bool);
};
exports.setIsEdgeSelectionChangeable = setIsEdgeSelectionChangeable;
var getIsEdgeSelectionChangeable = function () {
    return isEdgeSelectionChangeable;
};
exports.getIsEdgeSelectionChangeable = getIsEdgeSelectionChangeable;
