"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeUserEditingFromEdge = exports.removeUserEditingFromNode = exports.addUserEditingToNode = void 0;
var lodash_1 = require("lodash");
function addUserEditingToNode(node, socketId) {
    var copy = __assign({}, node);
    copy.data.editing = socketId;
    copy.selectable = false;
    copy.draggable = false;
    copy.connectable = false;
    copy.deletable = false;
    copy.selected = false;
    return copy;
}
exports.addUserEditingToNode = addUserEditingToNode;
function removeUserEditingFromNode(node) {
    var copy = __assign({}, node);
    delete copy.data.editing;
    delete copy.selectable;
    delete copy.draggable;
    delete copy.connectable;
    delete copy.deletable;
    delete copy.selected;
    return copy;
}
exports.removeUserEditingFromNode = removeUserEditingFromNode;
function removeUserEditingFromEdge(edge) {
    var copy = (0, lodash_1.cloneDeep)(edge);
    if (copy.data)
        copy.data.editing = null;
    return copy;
}
exports.removeUserEditingFromEdge = removeUserEditingFromEdge;
