"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onFetchLatestComment = exports.onApplyLabelChangesToCells = exports.onClearCell = exports.onPriorityCellChange = exports.onCountryCellChange = exports.onLabelCellChange = exports.onDropDownCellChange = exports.onFileCellChange = exports.onFileCellUpload = exports.onLinkCellChange = exports.onLongTextCellChange = exports.onClockCellChange = exports.onTimeTrackingCellRemove = exports.onTimeTrackingCellEdit = exports.onTimeTrackingCellAdd = exports.onCheckBoxCellChange = exports.onDateRangeCellChange = exports.onDateCellChange = exports.onNumberCellChange = exports.onTextCellChange = exports.onPeopleCellChange = exports.onStatusCellChange = void 0;
var types = __importStar(require("../../cellConstants"));
function onStatusCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.STATUS_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onStatusCellChange = onStatusCellChange;
function onPeopleCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.PERSON_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onPeopleCellChange = onPeopleCellChange;
function onTextCellChange() {
    console.log("text changed");
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.TEXT_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onTextCellChange = onTextCellChange;
function onNumberCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.NUMBER_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onNumberCellChange = onNumberCellChange;
function onDateCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.DATE_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onDateCellChange = onDateCellChange;
function onDateRangeCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.DATE_RANGE_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onDateRangeCellChange = onDateRangeCellChange;
function onCheckBoxCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.CHECK_BOX_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onCheckBoxCellChange = onCheckBoxCellChange;
function onTimeTrackingCellAdd() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.ADD_TIME_TRACKING_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onTimeTrackingCellAdd = onTimeTrackingCellAdd;
function onTimeTrackingCellEdit() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.EDIT_TIME_TRACKING_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onTimeTrackingCellEdit = onTimeTrackingCellEdit;
function onTimeTrackingCellRemove() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.REMOVE_TIME_TRACKING_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onTimeTrackingCellRemove = onTimeTrackingCellRemove;
function onClockCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.CLOCK_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onClockCellChange = onClockCellChange;
function onLongTextCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.LONG_TEXT_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onLongTextCellChange = onLongTextCellChange;
function onLinkCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.LINK_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onLinkCellChange = onLinkCellChange;
function onFileCellUpload() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.FILE_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onFileCellUpload = onFileCellUpload;
function onFileCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.FILE_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onFileCellChange = onFileCellChange;
function onDropDownCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.DROP_DOWN_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onDropDownCellChange = onDropDownCellChange;
function onLabelCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.LABEL_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onLabelCellChange = onLabelCellChange;
function onCountryCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.COUNTRY_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onCountryCellChange = onCountryCellChange;
function onPriorityCellChange() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({
                type: types.PRIORITY_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onPriorityCellChange = onPriorityCellChange;
function onClearCell() {
    return function (dispatch) {
        return function (_a) {
            var taskId = _a.taskId, taskGroupId = _a.taskGroupId, key = _a.key, value = _a.value;
            dispatch({
                type: types.CLEAR_CELL_SUCCESS,
                taskId: taskId,
                taskGroupId: taskGroupId,
                key: key,
                value: value,
            });
        };
    };
}
exports.onClearCell = onClearCell;
function onApplyLabelChangesToCells() {
    return function (dispatch) {
        return function (_a) {
            var field = _a.field, labels = _a.labels;
            dispatch({ type: types.APPLY_LABEL_CHANGES_TO_CELLS, field: field, labels: labels });
        };
    };
}
exports.onApplyLabelChangesToCells = onApplyLabelChangesToCells;
function onFetchLatestComment() {
    return function (dispatch) {
        return function (_a) {
            var key = _a.key, taskGroupId = _a.taskGroupId, taskId = _a.taskId, value = _a.value;
            dispatch({ type: types.FETCH_LATEST_COMMENT, key: key, taskGroupId: taskGroupId, taskId: taskId, value: value });
        };
    };
}
exports.onFetchLatestComment = onFetchLatestComment;
