"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useContextMenu = function () {
    var _a = (0, react_1.useState)(false), show = _a[0], setShow = _a[1];
    var handleContextMenu = (0, react_1.useCallback)(function (event) {
        event.preventDefault();
        setShow(true);
    }, [setShow]);
    var handleClick = (0, react_1.useCallback)(function () {
        if (show) {
            setShow(false);
        }
    }, [show]);
    (0, react_1.useEffect)(function () {
        document.addEventListener("click", handleClick);
        document.addEventListener("contextmenu", handleContextMenu);
        return function () {
            document.removeEventListener("click", handleClick);
            document.removeEventListener("contextmenu", handleContextMenu);
        };
    });
    return { show: show, setShow: setShow };
};
exports.default = useContextMenu;
