"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateState = exports.removeDropdownConditionLabel = exports.addDropdownConditionLabel = exports.updateCondition = exports.removeCondition = exports.addCondition = exports.removeTrigger = exports.updateTrigger = exports.addTrigger = exports.updateHandler = exports.removeHandler = exports.addHandler = void 0;
var constants_1 = require("./constants");
var mappings_1 = require("./mappings");
var objectIterationMethods_1 = require("@utils/objectIterationMethods");
function addHandler(type) {
    return { type: constants_1.ADD_HANDLER, payload: (0, mappings_1.getInitialHandler)(type) };
}
exports.addHandler = addHandler;
function removeHandler(index) {
    return { type: constants_1.REMOVE_HANDLER, payload: index };
}
exports.removeHandler = removeHandler;
function updateHandler(handler, index) {
    return { type: constants_1.UPDATE_HANDLER, payload: { handler: handler, index: index } };
}
exports.updateHandler = updateHandler;
function addTrigger(trigger) {
    return { type: constants_1.ADD_TRIGGER, payload: (0, mappings_1.getInitialTrigger)(trigger) };
}
exports.addTrigger = addTrigger;
function updateTrigger(trigger) {
    return { type: constants_1.UPDATE_TRIGGER, payload: trigger };
}
exports.updateTrigger = updateTrigger;
function removeTrigger() {
    return { type: constants_1.REMOVE_TRIGGER };
}
exports.removeTrigger = removeTrigger;
function addCondition(type) {
    return { type: constants_1.ADD_CONDITION, payload: (0, mappings_1.getInitialCondition)(type) };
}
exports.addCondition = addCondition;
function removeCondition(index) {
    return { type: constants_1.REMOVE_CONDITION, payload: index };
}
exports.removeCondition = removeCondition;
function updateCondition(condition, index) {
    return { type: constants_1.UPDATE_CONDITION, payload: { condition: condition, index: index } };
}
exports.updateCondition = updateCondition;
function addDropdownConditionLabel(index, value) {
    return { type: constants_1.ADD_DROPDOWN_CONDITION_LABEL, payload: { index: index, option: value } };
}
exports.addDropdownConditionLabel = addDropdownConditionLabel;
function removeDropdownConditionLabel(index, value) {
    return { type: constants_1.REMOVE_DROPDOWN_CONDITION_LABEL, payload: { index: index, option: value } };
}
exports.removeDropdownConditionLabel = removeDropdownConditionLabel;
var valuesAreValid = function (obj) {
    return (0, objectIterationMethods_1.getValues)(obj).every(function (val) { return val !== null; });
};
var isValidCondition = function (condition) {
    return condition.type !== "new_condition" && valuesAreValid(condition);
};
var isValidHandler = function (handler) {
    return handler.type !== "new_handler" && valuesAreValid(handler);
};
var isValidTrigger = function (trigger) {
    if ("scheduledOccurrence" in trigger) {
        return valuesAreValid({
            trigger: trigger,
            scheduledOccurrence: trigger.scheduledOccurrence || 1,
        });
    }
    return valuesAreValid(trigger);
};
function validateState(trigger, conditions, handlers) {
    var hasValidTrigger = isValidTrigger(trigger);
    var hasValidConditions = conditions.every(isValidCondition);
    var hasValidHandlers = handlers.length > 0 && handlers.every(isValidHandler);
    return hasValidTrigger && hasValidConditions && hasValidHandlers;
}
exports.validateState = validateState;
