"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var groupNodeUtils_1 = require("@components/Workspace/Node/utils/groupNodeUtils");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var utils_1 = require("@components/Flow/HelperLines/utils");
var useMultiSelect_1 = require("./useMultiSelect");
var useBaseEdge_1 = require("./useBaseEdge");
var useChangeElements = function (_a) {
    var dispatch = _a.dispatch, nodeElements = _a.nodeElements, edgeElements = _a.edgeElements, orgData = _a.orgData, showAlertCustomRules = _a.showAlertCustomRules, showHelperLines = _a.showHelperLines;
    var _b = (0, react_1.useState)(undefined), helperLineHorizontal = _b[0], setHelperLineHorizontal = _b[1];
    var _c = (0, react_1.useState)(undefined), helperLineVertical = _c[0], setHelperLineVertical = _c[1];
    (0, useMultiSelect_1.useMultiSelect)();
    (0, useBaseEdge_1.useBaseEdge)();
    // const [helperLinePwcHorizontal, setHelperLinePwcHorizontal] = useState<
    //   number | undefined
    // >(undefined);
    // const [helperLinePwcVertical, setHelperLinePwcVertical] = useState<number | undefined>(
    //   undefined
    // );
    var customApplyNodeChanges = (0, react_1.useCallback)(function (changes, nodes) {
        var _a, _b;
        // reset the helper lines (clear existing lines, if any)
        setHelperLineHorizontal(undefined);
        setHelperLineVertical(undefined);
        // if (orgData?.cvr === "42303542" && !customRulesAlerts) {
        //   setHelperLinePwcHorizontal(undefined);
        //   setHelperLinePwcVertical(undefined);
        // }
        // this will be true if it's a single node being dragged
        // inside we calculate the helper lines and snap position for the position where the node is being moved to
        if (changes.length === 1 &&
            changes[0].type === "position" &&
            changes[0].dragging &&
            changes[0].position &&
            showHelperLines) {
            var helperLines = (0, utils_1.getHelperLines)(changes[0], nodes);
            changes[0].position.x = (_a = helperLines.snapPosition.x) !== null && _a !== void 0 ? _a : changes[0].position.x;
            changes[0].position.y = (_b = helperLines.snapPosition.y) !== null && _b !== void 0 ? _b : changes[0].position.y;
            setHelperLineHorizontal(helperLines.horizontal);
            setHelperLineVertical(helperLines.vertical);
            // if (orgData?.cvr === "42303542" && !customRulesAlerts) {
            //   const helperLinesPwc = getHelperLinesPwc(changes[0], nodes);
            //   changes[0].position.x = helperLinesPwc.snapPosition.x ?? changes[0].position.x;
            //   changes[0].position.y = helperLinesPwc.snapPosition.y ?? changes[0].position.y;
            //   setHelperLinePwcHorizontal(helperLinesPwc.horizontal);
            //   setHelperLinePwcVertical(helperLinesPwc.vertical);
            // }
        }
        // @ts-ignore
        return (0, reactflow_1.applyNodeChanges)(changes, nodes);
    }, [orgData === null || orgData === void 0 ? void 0 : orgData.cvr, showAlertCustomRules, showHelperLines]);
    var onSelectionStart = (0, react_1.useCallback)(function () {
        (0, useMultiSelect_1.setIsMultiSelecting)(true);
    }, []);
    var onSelectionEnd = (0, react_1.useCallback)(function () {
        var nodeSelectionChanges = [];
        var edgeSelectionChanges = [];
        Array.from(document.querySelectorAll(".react-flow__node.selected")).forEach(function (node) {
            var id = node.getAttribute("data-id");
            if (id) {
                nodeSelectionChanges.push({
                    id: id,
                    type: "select",
                    selected: true,
                });
            }
        });
        Array.from(document.querySelectorAll(".react-flow__edge.selected")).forEach(function (edge) {
            var pathWithEdgeId = edge.querySelector(".react-flow__edge-path");
            if (!pathWithEdgeId) {
                return;
            }
            var id = pathWithEdgeId.getAttribute("id");
            if (id) {
                edgeSelectionChanges.push({
                    id: id,
                    type: "select",
                    selected: true,
                });
            }
        });
        if (nodeSelectionChanges.length !== 0) {
            var appliesNodes = customApplyNodeChanges(nodeSelectionChanges, nodeElements);
            dispatch((0, workspaceActions_http_1.changeNodes)(appliesNodes));
        }
        if (edgeSelectionChanges.length !== 0) {
            dispatch((0, workspaceActions_http_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(edgeSelectionChanges, edgeElements)));
        }
        (0, useMultiSelect_1.setIsMultiSelecting)(false);
    }, [nodeElements, edgeElements, customApplyNodeChanges]);
    var onNodesChange = (0, react_1.useCallback)(function (changes) {
        if ((0, useMultiSelect_1.isMultiSelecting)()) {
            return;
        }
        var appliesNodes = customApplyNodeChanges(changes, nodeElements);
        if ("id" in changes[0] &&
            changes[0].id.includes("group") &&
            changes[0].type === "dimensions") {
            appliesNodes = appliesNodes.sort(groupNodeUtils_1.sortNodes);
        }
        dispatch((0, workspaceActions_http_1.changeNodes)(appliesNodes));
    }, [nodeElements, showHelperLines]);
    var onEdgesChange = (0, react_1.useCallback)(function (changes) {
        changes = changes.filter(function (change) { return !(change.type === "select" && !(0, useBaseEdge_1.getIsEdgeSelectionChangeable)()); });
        if (changes.length === 0) {
            return;
        }
        if ((0, useMultiSelect_1.isMultiSelecting)()) {
            return;
        }
        dispatch((0, workspaceActions_http_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(changes, edgeElements)));
    }, [edgeElements]);
    return {
        onNodesChange: onNodesChange,
        onEdgesChange: onEdgesChange,
        helperLineHorizontal: helperLineHorizontal,
        helperLineVertical: helperLineVertical,
        onSelectionEnd: onSelectionEnd,
        onSelectionStart: onSelectionStart,
        // helperLinePwcHorizontal,
        // helperLinePwcVertical,
    };
};
exports.default = useChangeElements;
